import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    ::slotted(bi-tab-panel) {
      display: none;
    }
    ::slotted(bi-tab-panel[aria-hidden="false"]) {
      display: flex;
    }

    .bi-tab-group-list {
      display: flex;
      overflow: auto;
      scrollbar-width: none;
      overscroll-behavior-x: contain;
      scroll-snap-type: x proximity;
      flex: 0 0 auto;
      box-shadow: inset 0 calc(var(--bi-size-border-weight) * -1)
        var(--bi-color-border-bright);
      background-color: var(--bi-color-background);
      background-image: radial-gradient(
          ellipse farthest-side at 0% 50%,
          var(--bi-color-neutral-default) 0%,
          var(--bi-color-background)
        ),
        radial-gradient(
          ellipse farthest-side at 100% 50%,
          var(--bi-color-neutral-default) 0%,
          var(--bi-color-background)
        );
      background-repeat: no-repeat;
      background-position: 0 calc(var(--bi-size-space-s) / 2),
        100% calc(var(--bi-size-space-s) / 2);
      background-size: var(--bi-size-space-m) var(--bi-size-control-block-m),
        var(--bi-size-space-m) var(--bi-size-control-block-m);
    }

    /** Scroll shadow technique is inspired by https://darn.es/building-tabs-in-web-components */
    .bi-tab-group-list::before,
    .bi-tab-group-list::after {
      content: "";
      box-sizing: content-box;
      align-self: stretch;
      min-inline-size: var(--bi-size-space-xl);
      margin-block-end: calc(var(--bi-size-border-weight) * 2);
    }
    .bi-tab-group-list::before {
      margin-inline-end: calc(
        -1 * (var(--bi-size-space-xl) + var(--bi-size-space-s))
      );
      padding-inline-start: var(--bi-size-space-s);
    }
    .bi-tab-group-list::after {
      margin-inline-start: calc(
        -1 * (var(--bi-size-space-xl) + var(--bi-size-space-s))
      );
      padding-inline-end: var(--bi-size-space-s);
      flex: 1;
    }
    .bi-tab-group-list::before,
    .bi-tab-group-list:dir(rtl)::after {
      box-shadow: inset var(--bi-size-space-xl) 0 var(--bi-size-space-s)
        calc(-1 * var(--bi-size-space-s)) var(--bi-color-background);
    }
    .bi-tab-group-list::after,
    .bi-tab-group-list:dir(rtl)::before {
      box-shadow: inset calc(-1 * var(--bi-size-space-xl)) 0
        var(--bi-size-space-s) calc(-1 * var(--bi-size-space-s))
        var(--bi-color-background);
    }

    .bi-tab-group-list::-webkit-scrollbar {
      display: none;
    }

    .bi-tab-group-list ::slotted(bi-tab) {
      flex: 0 0 auto;
      scroll-snap-align: start;
      scroll-snap-stop: normal;
    }

    .bi-tab-group-content {
      flex: 1;
      display: flex;
      overflow: hidden;
    }
  `];
