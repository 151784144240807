import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      inline-size: 100%;
      display: flex;
    }

    .bi-tab-panel {
      inline-size: 100%;
      display: flex;
      background-color: var(--bi-color-background);
      padding: var(--bi-tab-panel-padding, var(--bi-size-space-m));
    }
  `];
