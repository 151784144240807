import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --_bi-dropdown-max-block-size: var(--bi-dropdown-max-block-size, none);
      position: relative;
      inline-size: 100%;
      max-inline-size: var(--_bi-dropdown-max-inline-size, max-content);
    }

    :host([expand]) {
      --_bi-dropdown-max-inline-size: none;
      --_bi-dropdown-item-max-inline-size: none;
    }

    .bi-dropdown-content {
      overflow: auto;
      max-block-size: var(--_bi-dropdown-max-block-size);
      padding: var(--_bi-dropdown-content-padding, var(--bi-size-space-xs));
      scroll-padding: var(--bi-size-space-xs);
    }
  `];
