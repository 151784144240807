var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from "lit";
import { customElement, property, query, queryAssignedElements, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import dropdownItemStyles from "./dropdown-item.styles";
import "../popover/popover.component";
import { classMap } from "lit/directives/class-map.js";
/**
 * A dropdown item is a clickable item in a dropdown.
 * @slot - The content of the dropdown item.
 * @slot start - The content to render before the dropdown item's content.
 * @slot end - The content to render after the dropdown item's content.
 * @slot sub - The dropdown item's sub items. Need to be `bi-dropdown-item` elements.
 */
let DropdownItem = class DropdownItem extends LitElement {
  constructor() {
    super(...arguments);
    /** When provided together with a href property, determines where to open the linked URL. */
    this.target = "_self";
    /** Whether the item is disabled. Only applies when href is not provided. */
    this.disabled = false;
    this.insideSelect = false;
    this.hasSub = false;
  }
  connectedCallback() {
    super.connectedCallback();
    this.insideSelect = this.closest(".bi-select") !== null;
  }
  render() {
    return html`<div
      class="bi-dropdown-item"
      @focusin=${this.hasSub ? this.openSub : nothing}
      @mouseover=${this.hasSub ? this.openSub : nothing}
      @focusout=${this.hasSub ? this.handleFocusOut : nothing}
      @mouseleave=${this.hasSub ? this.closeSub : nothing}
    >
      ${this.getContentHTML()} ${this.getSubHTML()}
    </div>`;
  }
  getContentHTML() {
    const contentHTML = html` <slot name="start"></slot>
      <div class="bi-dropdown-item-content">
        <slot></slot>
      </div>
      <slot name="end"></slot>
      ${this.getSubIconHTML()}`;
    const itemClasses = classMap({
      "bi-dropdown-item-button": true,
      "bi-dropdown-item-button--insideSelect": this.insideSelect
    });
    return this.href ? html`
          <a
            class=${itemClasses}
            href=${this.href}
            target=${ifDefined(this.target)}
            aria-controls=${this.hasSub ? "sub" : nothing}
          >
            ${contentHTML}
          </a>
        ` : html`
          <button
            class=${itemClasses}
            ?disabled=${this.disabled}
            aria-controls=${this.hasSub ? "sub" : nothing}
          >
            ${contentHTML}
          </button>
        `;
  }
  getSubHTML() {
    const subClasses = classMap({
      "bi-dropdown-item-sub": true,
      "bi-dropdown-item-sub--empty": !this.hasSub
    });
    return html`<bi-popover
      class="bi-dropdown-item-popover"
      id="sub"
      placement="right-start"
      offset="4"
    >
      <div class=${subClasses}>
        <slot name="sub" @slotchange=${this.handleSubSlotChange}></slot>
      </div>
    </bi-popover>`;
  }
  getSubIconHTML() {
    return this.hasSub ? html`<svg
          class="bi-dropdown-item-subIcon"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-169.4 169.4-169.4-169.3c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
            fill="currentColor"
          />
        </svg>` : nothing;
  }
  handleSubSlotChange() {
    this.hasSub = this.slottedSubElements.length > 0;
  }
  handleFocusOut(event) {
    if (!this.contains(event.relatedTarget)) {
      this.closeSub();
    }
  }
  openSub() {
    this.popoverElement.open();
  }
  closeSub() {
    this.popoverElement.close();
  }
};
DropdownItem.styles = dropdownItemStyles;
__decorate([query("bi-popover")], DropdownItem.prototype, "popoverElement", void 0);
__decorate([queryAssignedElements({
  slot: "sub",
  selector: "bi-dropdown-item"
})], DropdownItem.prototype, "slottedSubElements", void 0);
__decorate([property()], DropdownItem.prototype, "variant", void 0);
__decorate([property()], DropdownItem.prototype, "href", void 0);
__decorate([property()], DropdownItem.prototype, "target", void 0);
__decorate([property({
  type: Boolean
})], DropdownItem.prototype, "disabled", void 0);
__decorate([state()], DropdownItem.prototype, "insideSelect", void 0);
__decorate([state()], DropdownItem.prototype, "hasSub", void 0);
DropdownItem = __decorate([customElement("bi-dropdown-item")], DropdownItem);
export { DropdownItem };
