import { RouterModule, Routes } from '@angular/router'

import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component'
import { AuthGuard } from './auth/auth.guard'
import { NgModule } from '@angular/core'
import { routes as adminRoutes } from './modules/admin/admin.routes'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'interviews'
  },
  {
    path: 'interviews',
    canActivate: [AuthGuard],
    title: 'Interviews',
    loadChildren: () => import('./modules/dashboard/interviews/interviews.module').then(m => m.InterviewsModule)
  },
  {
    path: 'patients',
    canActivate: [AuthGuard],
    title: 'Patients',
    loadChildren: () => import('./modules/dashboard/patients/patients.module').then(m => m.PatientsModule)
  },
  {
    path: 'agenda',
    canActivate: [AuthGuard],
    title: 'Agenda',
    loadChildren: () => import('./modules/agenda/agenda.module').then(m => m.AgendaModule)
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    title: 'Account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: { roles: ['TenantAdmin', 'AnalyticsRead'] },
    title: 'Admin',
    loadComponent: () => import('./modules/admin/admin.component').then(m => m.AdminComponent),
    children: adminRoutes
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  },
  {
    path: '**',
    redirectTo: 'interviews'
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true
    })
  ],
  exports: [RouterModule],
  providers: [{ provide: 'authGuard', useValue: () => true }]
})
export class AppRoutingModule {}
