export class LightLinkController {
  constructor(host, target) {
    this.target = target;
    this.hadFirstUpdate = false;
    this.host = host;
    this.host.addController(this);
    this.observer = new MutationObserver(records => {
      this.processTarget(records[0].target);
    });
  }
  hostUpdated() {
    if (!this.hadFirstUpdate && this.target()) {
      this.processTarget(this.target());
      this.observe(this.target());
      this.hadFirstUpdate = true;
    }
  }
  hostConnected() {
    // in case the host has rendered, disconnected, and is now reconnected
    if (this.hadFirstUpdate && this.target()) {
      this.processTarget(this.target());
      this.observe(this.target());
    }
  }
  hostDisconnected() {
    this.observer.disconnect();
  }
  observe(target) {
    this.observer.observe(target, {
      characterData: true,
      childList: true,
      attributes: true,
      subtree: true
    });
  }
  processTarget(target) {
    this.text = target.textContent;
    this.host.requestUpdate();
  }
}
