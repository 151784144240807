var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";
import treeStyles from "./tree.styles";
/**
 * A tree is a list of items that contain content or another tree.
 * @slot - The slot to place the tree items in.
 */
let Tree = class Tree extends LitElement {
  constructor() {
    super(...arguments);
    /** Aligns the content of the tree items. Uses CSS subgrid internally. */
    this.alignContent = false;
  }
  render() {
    return html`
      <div class="bi-tree" role="tree">
        <slot></slot>
      </div>
    `;
  }
};
Tree.styles = treeStyles;
__decorate([queryAssignedElements({
  selector: "bi-tree-item"
})], Tree.prototype, "slottedTreeItems", void 0);
__decorate([property({
  type: Boolean,
  attribute: "align-content"
})], Tree.prototype, "alignContent", void 0);
Tree = __decorate([customElement("bi-tree")], Tree);
export { Tree };
