import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { TenantAdminService } from '../services/tenant-admin.service';
import { Observable, catchError, map, of } from 'rxjs';
import {
  TenantUser,
  UserCreationContext,
  UserGroup,
  UserSortFields,
} from '../models/user.model';
import { SortOrder } from '../data/enums';
import { PAGINATION_LIMIT } from '../data/constants';
import { Resolved } from '../models/resolved.model';

export const usersResolver: ResolveFn<Observable<Resolved<TenantUser[]>>> = (
  route
) => {
  const tenantAdminService = inject(TenantAdminService);
  return tenantAdminService
    .getUsers({
      text: route.queryParams['search'],
      selectedPage: route.queryParams['page'] || 1,
      pageSize: PAGINATION_LIMIT,
      sortField: UserSortFields.CreatedOn,
      sortOrder: SortOrder.Descending,
      creationContext: UserCreationContext.BackOffice,
    })
    .pipe(
      map((response) => ({
        data: response.listData,
        filterSettings: response.filterSettings,
      })),
      catchError((error) =>
        of({
          error: {
            status: error.status,
            message: error.message,
          },
        })
      )
    );
};

export const userResolver: ResolveFn<Observable<Resolved<TenantUser>>> = (
  route
) => {
  const tenantAdminService = inject(TenantAdminService);
  return tenantAdminService.getUser(route.params['id']).pipe(
    map((response) => ({
      data: response.data,
    })),
    catchError((error) =>
      of({
        error: {
          status: error.status,
          message: error.message,
        },
      })
    )
  );
};

export const userGroupsResolver: ResolveFn<
  Observable<Resolved<UserGroup[]>>
> = () => {
  const tenantAdminService = inject(TenantAdminService);
  return tenantAdminService.getUserGroups().pipe(
    map((response) => ({
      data: response.listData,
    })),
    catchError((error) =>
      of({
        error: {
          status: error.status,
          message: error.message,
        },
      })
    )
  );
};
