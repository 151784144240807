var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import badgeStyles from "./badge.styles";
import "../button/button.component";
import aiIcon from "../shared/icons/ai.icon";
/**
 * A badge indicates a status, notification, or event that appears in relativity with the underlying object.
 * @slot - The slot to place the badge content in.
 * @slot start - The content to render before the content.
 * @slot end - The content to render after the content.
 */
let Badge = class Badge extends LitElement {
  render() {
    return html`
      <span class="bi-badge">
        <slot name="start"></slot>
        ${this.variant === "ai" ? aiIcon("bi-badge-aiIcon") : nothing}
        <slot></slot>
        <slot name="end"></slot>
      </span>
    `;
  }
};
Badge.styles = badgeStyles;
__decorate([property()], Badge.prototype, "variant", void 0);
Badge = __decorate([customElement("bi-badge")], Badge);
export { Badge };
