var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { adoptStyles } from "lit";
import { customElement, property } from "lit/decorators.js";
import { Input } from "../input/input.component";
import { supportsAdoptingStyleSheets } from "lit";
/**
 * An unscoped version of the single-line text input. Has the same properties, slots and CSS custom properties as a regular input. Use this if you want to support password managers.
 */
let InputUnscoped = class InputUnscoped extends Input {
  constructor() {
    super(...arguments);
    /** Adds attributes to the host element to effectively target the unscoped element in CSS. */
    this.unscoped = true;
    this.unscopedFormControl = true;
  }
  connectedCallback() {
    super.connectedCallback();
    if (supportsAdoptingStyleSheets) {
      document.adoptedStyleSheets = Input.styles.map(style => style.styleSheet);
    } else {
      adoptStyles(this.renderRoot, Input.styles);
    }
  }
  /** Disables the Shadow DOM. */
  createRenderRoot() {
    return this;
  }
};
__decorate([property({
  reflect: true,
  attribute: "bi-unscoped",
  type: Boolean
})], InputUnscoped.prototype, "unscoped", void 0);
__decorate([property({
  reflect: true,
  attribute: "bi-unscoped-form-control",
  type: Boolean
})], InputUnscoped.prototype, "unscopedFormControl", void 0);
InputUnscoped = __decorate([customElement("bi-input-unscoped")], InputUnscoped);
export { InputUnscoped };
