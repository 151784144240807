var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { autoUpdate, computePosition, flip, hide, offset, shift } from "@floating-ui/dom";
import popoverStyles from "./popover.styles";
import * as tokens from "@mybingli/ds-tokens";
/**
 * Displays popover content on top of other page content.
 * @slot - The slot to place the popover content in.
 * @fires opened - Dispatched whenever the popover is opened.
 * @fires closed - Dispatched whenever the popover is closed, also from within.
 * @cssprop [--bi-popover-z-index = 3] - The z-index of the popover.
 * @cssprop [--bi-popover-max-inline-size = none] - The maximum inline size of the popover.
 * @cssprop [--bi-popover-min-inline-size = 0] - The minimum inline size of the popover.
 */
let Popover = class Popover extends LitElement {
  constructor() {
    super(...arguments);
    /** Whether the popover is opened. */
    this.opened = false;
    /** The placement of the popover in relation to the anchor. */
    this.placement = "bottom";
    /** The shift of the popover in pixels. */
    this.shift = tokens.biSizeSpaceM;
    /** The offset of the popover in pixels. */
    this.offset = tokens.biSizeSpaceXxs;
    this.handleDocumentInteraction = event => {
      const insideHost = event.composedPath().some(node => node === this);
      const insideAnchor = event.composedPath().some(node => node === this.anchor);
      if (!(insideAnchor || insideHost)) {
        this.close();
      }
    };
    this.updatePosition = async () => {
      var _a;
      if (this.anchor) {
        const {
          x,
          y,
          middlewareData
        } = await computePosition(this.anchor, this, {
          strategy: "fixed",
          placement: this.placement,
          middleware: [flip(), shift({
            padding: this.shift,
            crossAxis: true
          }), offset(this.offset), hide()]
        });
        if (((_a = middlewareData.hide) === null || _a === void 0 ? void 0 : _a.referenceHidden) && this.opened) {
          this.close();
        }
        this.style.setProperty("--_bi-popover-position-x", `${x}px`);
        this.style.setProperty("--_bi-popover-position-y", `${y}px`);
      }
    };
  }
  disconnectedCallback() {
    var _a;
    (_a = this.cleanupAutoUpdate) === null || _a === void 0 ? void 0 : _a.call(this);
    document.removeEventListener("mousedown", this.handleDocumentInteraction);
    document.removeEventListener("touchstart", this.handleDocumentInteraction);
    super.disconnectedCallback();
  }
  connectedCallback() {
    super.connectedCallback();
    const insideSelect = this.closest(".bi-dropdown--insideSelect");
    setTimeout(() => {
      // Using setTimeout ensures we wait until the anchor is added to the DOM. This is important for dynamic environments like Angular.
      this.anchor = this.getAnchor();
      if (this.anchor) {
        if (insideSelect) {
          this.anchor.addEventListener("mousedown", () => this.open());
        } else {
          this.anchor.addEventListener("click", () => this.toggle());
        }
        this.updatePosition();
      }
    });
  }
  updated(_changedProperties) {
    if (_changedProperties.has("opened")) {
      this.handleOpenedChange();
    }
  }
  render() {
    return html`
      <div class="bi-popover" role="menu">
        <slot></slot>
      </div>
    `;
  }
  /** Opens the popover programmatically. */
  open() {
    this.opened = true;
    this.dispatchEvent(new Event("opened", {
      bubbles: false,
      composed: true
    }));
    document.addEventListener("mousedown", this.handleDocumentInteraction);
    document.addEventListener("touchstart", this.handleDocumentInteraction);
  }
  /** Closes the popover programmatically. */
  close() {
    this.opened = false;
    this.dispatchEvent(new Event("closed", {
      bubbles: false,
      composed: true
    }));
    document.removeEventListener("mousedown", this.handleDocumentInteraction);
    document.removeEventListener("touchstart", this.handleDocumentInteraction);
  }
  /** Toggles the popover programmatically. */
  toggle() {
    this.opened ? this.close() : this.open();
  }
  handleOpenedChange() {
    var _a;
    if (this.opened && this.anchor) {
      this.cleanupAutoUpdate = autoUpdate(this.anchor, this, this.updatePosition);
    } else {
      (_a = this.cleanupAutoUpdate) === null || _a === void 0 ? void 0 : _a.call(this);
    }
  }
  getAnchor() {
    const rootNode = this.getRootNode();
    const anchor = rootNode.querySelector(`[aria-controls='${this.id}']`);
    /** If the anchor is inside a Shadow DOM slot, return the first assigned element. Otherwise return the anchor itself. */
    if (anchor instanceof HTMLSlotElement) {
      return anchor.assignedElements()[0];
    }
    return anchor || undefined;
  }
};
/** Sets the styles. */
Popover.styles = popoverStyles;
__decorate([property({
  type: Boolean,
  reflect: true
})], Popover.prototype, "opened", void 0);
__decorate([property()], Popover.prototype, "placement", void 0);
__decorate([property({
  type: Number
})], Popover.prototype, "shift", void 0);
__decorate([property({
  type: Number
})], Popover.prototype, "offset", void 0);
Popover = __decorate([customElement("bi-popover")], Popover);
export { Popover };
