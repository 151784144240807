<h1 class="logo"><a routerLink="/" class="logo-link">Bingli</a></h1>

<bi-tab-group class="tabs">
  <bi-tab
    *ngFor="let nav of navItems"
    slot="tab"
    [attr.selected]="tab.isActive ? true : null"
    (selected)="handleTabSelected(nav.route)"
  >
    <a
      #tab="routerLinkActive"
      [routerLink]="nav.route"
      routerLinkActive
      slot="link"
      >{{ nav.label | translate }}</a
    >
  </bi-tab>
</bi-tab-group>

<div class="actions">
  <app-user-menu></app-user-menu>

  <bingli-language-menu
    [usePractitionerLanguages]="true"
    [selectedLanguage]="(currentLanguage$ | async) || ''"
    (selectedLanguageChange)="handleLanguageChange($event)"
  ></bingli-language-menu>

  <bi-select
    expand
    class="actions-selectHcs"
    (change)="handleHcsChange($event)"
    [attr.value]="currentHcsOption()?.healthCareServiceId"
  >
    <option
      *ngFor="let hcs of availableHcs()"
      [attr.value]="hcs.healthCareServiceId"
    >
      {{ hcs.tenantName }} - {{ hcs.healthCareServiceName }}
    </option>
  </bi-select>
</div>
