import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    ::slotted([slot="proxy"]) {
      display: none;
    }

    ::slotted([slot="link"]) {
      position: absolute;
      z-index: 3;
      inset: 0;
      border-radius: var(--_bi-button-border-radius);
      opacity: 0;
    }

    :host {
      --_bi-button-block-size: var(--bi-size-control-block-m);
      --_bi-button-inline-size: 100%;
      --_bi-button-max-inline-size: max-content;
      --_bi-button-font-size: var(--bi-size-font-m);
      --_bi-button-color: var(--bi-button-color, var(--bi-color-text-default));
      --_bi-button-color--hover: var(
        --bi-button-color--hover,
        var(--bi-color-text-default)
      );
      --_bi-button-background-color: var(
        --bi-button-background-color,
        var(--bi-color-background)
      );
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-neutral-faint)
      );
      --_bi-button-border-color: var(
        --bi-button-border-color,
        var(--bi-color-border-default)
      );
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        var(--bi-color-border-shade)
      );
      --_bi-button-color-outline: var(
        --bi-button-color-outline,
        var(--bi-color-outline)
      );
      --_bi-button-opacity: 1;
      --_bi-button-border-radius: var(--bi-size-border-radius-m);
      --_bi-button-gap: var(--bi-size-space-s);
      --_bi-button-padding-block: 0;
      --_bi-button-padding-inline: var(--bi-size-space-m);
      --_bi-button-justify-content: center;
      --_bi-button-toggleIcon-inline-size: var(--bi-size-space-m);
      --_bi-button-z-index: var(--bi-button-z-index, 0);
      --_bi-button-target-increase: var(--bi-button-target-increase, 0px);

      display: flex;
      inline-size: var(--_bi-button-inline-size);
      min-inline-size: 0;
      max-inline-size: var(--_bi-button-max-inline-size);
      position: relative;
    }

    :host([size="s"]) {
      --_bi-button-block-size: var(--bi-size-control-block-s);
      --_bi-button-font-size: var(--bi-size-font-s);
      --_bi-button-padding-inline: var(--bi-size-space-s);
      --_bi-button-gap: var(--bi-size-space-xs);
      --_bi-button-toggleIcon-inline-size: var(--bi-size-space-s);
    }

    :host([size="xs"]) {
      --_bi-button-block-size: var(--bi-size-control-block-xs);
      --_bi-button-font-size: var(--bi-size-font-xs);
      --_bi-button-padding-inline: var(--bi-size-space-xs);
      --_bi-button-gap: var(--bi-size-space-xxs);
      --_bi-button-toggleIcon-inline-size: var(--bi-size-space-xs);
    }

    :host([size="xxs"]) {
      --_bi-button-block-size: var(--bi-size-control-block-xxs);
      --_bi-button-font-size: var(--bi-size-font-xs);
      --_bi-button-padding-inline: var(--bi-size-space-xs);
      --_bi-button-gap: var(--bi-size-space-xxs);
      --_bi-button-toggleIcon-inline-size: var(--bi-size-space-xs);
    }

    :host([square]) {
      --_bi-button-inline-size: var(--_bi-button-block-size);
      --_bi-button-padding-inline: 0;
    }

    :host([expand]) {
      --_bi-button-max-inline-size: none;
    }

    :host([pressed]) {
      --_bi-button-color: var(--bi-color-primary-default);
      --_bi-button-color--hover: var(--bi-color-primary-default);
      --_bi-button-background-color: var(--bi-color-primary-faint);
      --_bi-button-background-color--hover: var(--bi-color-primary-faint);
      --_bi-button-border-color: var(--bi-color-primary-default);
      --_bi-button-border-color--hover: var(--bi-color-primary-default);
      --_bi-button-color-outline: var(--bi-color-primary-default);
    }

    :host([justify-content="start"]) {
      --_bi-button-justify-content: flex-start;
    }

    :host([justify-content="end"]) {
      --_bi-button-justify-content: flex-end;
    }

    :host([justify-content="space-between"]) {
      --_bi-button-justify-content: space-between;
    }

    :host([disabled]) {
      --_bi-button-opacity: var(--bi-opacity-disabled);
    }

    :host([variant="primary"]) {
      --_bi-button-color: var(--bi-button-color, var(--bi-color-text-on-dark));
      --_bi-button-color--hover: var(
        --bi-button-color--hover,
        var(--bi-color-text-on-dark)
      );
      --_bi-button-background-color: var(
        --bi-button-background-color,
        var(--bi-color-primary-default)
      );
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-primary-bright)
      );
      --_bi-button-border-color: var(
        --bi-button-border-color,
        var(--_bi-button-background-color)
      );
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        var(--_bi-button-background-color--hover)
      );
      --_bi-button-color-outline: var(
        --bi-button-color-outline,
        var(--bi-color-primary-default)
      );
    }

    :host([variant="primary-outline"]) {
      --_bi-button-color: var(
        --bi-button-color,
        var(--bi-color-primary-default)
      );
      --_bi-button-color--hover: var(
        --bi-button-color--hover,
        var(--bi-color-primary-default)
      );
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-primary-faint)
      );
      --_bi-button-border-color: var(
        --bi-button-border-color,
        var(--bi-color-primary-default)
      );
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        var(--bi-color-primary-shade)
      );
      --_bi-button-color-outline: var(
        --bi-button-color-outline,
        var(--bi-color-primary-default)
      );
    }

    :host([variant="plain"]),
    :host([variant="danger-plain-hover"]) {
      --_bi-button-background-color: var(
        --bi-button-background-color,
        transparent
      );
      --_bi-button-border-color: var(--bi-button-border-color, transparent);
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        transparent
      );
    }

    :host([variant="danger"]) {
      --_bi-button-color: var(--bi-button-color, var(--bi-color-text-on-dark));
      --_bi-button-color--hover: var(
        --bi-button-color--hover,
        var(--bi-color-text-on-dark)
      );
      --_bi-button-background-color: var(
        --bi-button-background-color,
        var(--bi-color-error-default)
      );
      --_bi-button-border-color: var(
        --bi-button-border-color,
        var(--_bi-button-background-color)
      );
      --_bi-button-color-outline: var(
        --bi-button-color-outline,
        var(--bi-color-error-default)
      );
    }

    :host([variant="danger"]) {
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-error-bright)
      );
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        var(--bi-color-error-bright)
      );
    }

    :host([variant="danger-plain-hover"]) {
      --_bi-button-color--hover: var(
        --bi-button-color,
        var(--bi-color-text-on-dark)
      );
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-error-default)
      );
    }

    :host([variant="danger-outline"]) {
      --_bi-button-color: var(--bi-button-color, var(--bi-color-error-default));
      --_bi-button-color--hover: var(
        --bi-button-color--hover,
        var(--bi-color-error-default)
      );
      --_bi-button-border-color: var(
        --bi-button-border-color,
        var(--bi-color-error-default)
      );
      --_bi-button-color-outline: var(
        --bi-button-color-outline,
        var(--bi-color-error-default)
      );
    }

    :host([variant="danger-outline"]) {
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-error-faint)
      );
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        var(--bi-color-error-default)
      );
    }

    :host([variant="danger-outline-hover"]) {
      --_bi-button-color--hover: var(
        --bi-button-color--hover,
        var(--bi-color-error-default)
      );
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-error-faint)
      );
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        var(--bi-color-error-default)
      );
    }

    :host([variant="ai"]) {
      --_bi-button-color: var(--bi-button-color, var(--bi-color-ai-default));
      --_bi-button-color--hover: var(
        --bi-button-color--hover,
        var(--_bi-button-color)
      );
      --_bi-button-background-color: var(
        --bi-button-background-color,
        var(--bi-color-ai-faint)
      );
      --_bi-button-background-color--hover: var(
        --bi-button-background-color--hover,
        var(--bi-color-ai-faint-shade)
      );
      --_bi-button-border-color: var(
        --bi-button-border-color,
        var(--_bi-button-background-color)
      );
      --_bi-button-border-color--hover: var(
        --bi-button-border-color--hover,
        var(--_bi-button-background-color--hover)
      );
      --_bi-button-color-outline: var(
        --bi-button-color-outline,
        var(--bi-color-ai-default)
      );
    }

    :host([direction="column"]) {
      --_bi-flex-direction: column;
      --_bi-button-block-size: auto;
      --_bi-button-padding-block: var(--bi-size-space-m);
    }
    :host([direction="column"][size="xs"]) {
      --_bi-button-padding-block: var(--bi-size-space-xs);
    }

    .bi-button {
      appearance: none;
      outline: none;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: var(--_bi-button-justify-content);
      flex-direction: var(--_bi-flex-direction, "row");
      gap: var(--_bi-button-gap);
      inline-size: calc(
        var(--_bi-button-inline-size) +
          var(--_bi-button-inline-size-correction, 0px)
      );
      block-size: calc(
        var(--_bi-button-block-size) +
          var(--_bi-button-block-size-correction, 0px)
      );
      padding-block: var(--_bi-button-padding-block);
      padding-inline: var(--_bi-button-padding-inline);
      font-family: var(--bi-font-family);
      font-size: var(--_bi-button-font-size);
      font-weight: var(--bi-font-weight-normal);
      border: var(--bi-size-border-weight) solid var(--_bi-button-border-color);
      border-inline-end-width: var(
        --_bi-button-border-inline-end-width,
        var(--bi-size-border-weight)
      );
      border-inline-start-width: var(
        --_button-border-inline-start-width,
        var(--bi-size-border-weight)
      );
      border-block-end-width: var(
        --_button-border-block-end-width,
        var(--bi-size-border-weight)
      );
      border-block-start-width: var(
        --_button-border-block-start-width,
        var(--bi-size-border-weight)
      );
      border-end-end-radius: var(
        --_bi-button-border-radius-end-end,
        var(--_bi-button-border-radius)
      );
      border-end-start-radius: var(
        --_bi-button-border-radius-end-start,
        var(--_bi-button-border-radius)
      );
      border-start-end-radius: var(
        --_bi-button-border-radius-start-end,
        var(--_bi-button-border-radius)
      );
      border-start-start-radius: var(
        --_bi-button-border-radius-start-start,
        var(--_bi-button-border-radius)
      );
      color: var(--_bi-button-color);
      background-color: var(--_bi-button-background-color);
      opacity: var(--_bi-button-opacity);
      box-shadow: 0 0 0 2px transparent;
      transition: all var(--bi-transition-duration-fast)
        var(--bi-transition-timing-default);
      transition-property: background-color, border-color, box-shadow;
      position: relative;
      z-index: var(--_bi-button-z-index);
    }
    .bi-button:after {
      content: "";
      inset: calc(var(--_bi-button-target-increase) * -1);
      position: absolute;
      z-index: -1;
      border-radius: inherit;
    }

    .bi-button:active,
    :host([light-link]:active) .bi-button,
    .bi-button:focus-visible,
    :host([light-link]:focus-within) .bi-button,
    .bi-button:not(:disabled):hover,
    :host([light-link]:not(:disabled):hover) .bi-button {
      --_bi-button-z-index: 2;
    }

    .bi-button:not(:disabled) {
      cursor: pointer;
    }

    :host([light-link]:hover) .bi-button:not(:disabled),
    .bi-button:not(:disabled):hover {
      color: var(--_bi-button-color--hover);
      background-color: var(--_bi-button-background-color--hover);
      border-color: var(--_bi-button-border-color--hover);
    }

    :host([light-link]:active) .bi-button,
    .bi-button:active {
      opacity: var(--bi-opacity-active);
    }

    :host([light-link]:focus-within) .bi-button,
    .bi-button:focus-visible {
      box-shadow: 0 0 0 var(--bi-size-border-weight) var(--bi-color-background),
        0 0 0 calc(var(--bi-size-border-weight) * 2)
          var(--_bi-button-color-outline);
    }

    .bi-button-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    :host([direction="column"]) .bi-button-content {
      inline-size: 100%;
    }

    .bi-button-toggleIcon {
      flex: 0 0 auto;
      inline-size: var(--_bi-button-toggleIcon-inline-size);
      vertical-align: middle;
    }

    .bi-button-aiIcon {
      flex: none;
      block-size: var(--bi-size-font-m);
      color: var(--bi-color-ai-default);
      vertical-align: middle;
    }
  `];
