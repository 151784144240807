import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from './intl/translation.loader';
import { IconModule } from './icon.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';
import { AboutDialogModule } from './components/dialogs/about-dialog/about-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatSnackBarModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AboutDialogModule,
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
  ],
  providers: [TranslateModule],
})
export class SharedModule {}
