import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    /** Base */
    :host {
      --_bi-popover-z-index: var(--bi-popover-z-index, 3);
      --_bi-popover-max-inline-size: var(--bi-popover-max-inline-size, none);
      --_bi-popover-min-inline-size: var(--bi-popover-min-inline-size, 0);
      --_bi-popover-position-x: 0;
      --_bi-popover-position-y: 0;
      position: fixed;
      left: var(--_bi-popover-position-x);
      top: var(--_bi-popover-position-y);
      z-index: var(--_bi-popover-z-index);
      max-inline-size: var(--_bi-popover-max-inline-size);
      min-inline-size: var(--_bi-popover-min-inline-size);
    }
    :host(:not([opened])) {
      visibility: hidden;
    }

    .bi-popover {
      inline-size: 100%;
      background-color: var(--bi-color-background);
      border-radius: var(--bi-size-border-radius-m);
      box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.15);
      border: var(--bi-size-border-weight) solid var(--bi-color-border-bright);
      overflow: hidden;
    }
  `];
