import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
import formControlStyles from "../shared/styles/form-control.styles";
export default [baseStyles, formControlStyles, css`
    :host {
      --_bi-textarea-inline-size: 100%;
      --_bi-textarea-block-size: var(--bi-textarea-block-size, auto);
      --_bi-textarea-max-inline-size: var(--bi-textarea-max-inline-size, 280px);
      --_bi-textarea-border-color: var(--bi-color-border-default);
      --_bi-textarea-border-color--focus: var(--bi-color-primary-default);

      display: flex;
      flex-direction: column;
      inline-size: var(--_bi-textarea-inline-size);
      max-inline-size: var(--_bi-textarea-max-inline-size);
      gap: var(--bi-size-space-xs);
    }

    :host([expand]) {
      --_bi-textarea-max-inline-size: none;
    }

    :host([error]) {
      --_bi-textarea-border-color: var(--bi-color-error-default);
      --_bi-textarea-border-color--focus: var(--bi-color-error-shade);
    }

    :host([resize="auto"]) {
      --_bi-textarea-resize: none;
      --_bi-textarea-overflow: hidden;
    }

    .bi-textarea {
      --row-height: calc(
        (var(--bi-size-font-m) * var(--bi-line-height)) +
          (2 * var(--bi-size-border-weight))
      );
      --padding-block: calc(
        (var(--bi-size-control-block-m) - var(--row-height)) / 2
      );
      font-family: var(--bi-font-family);
      font-size: var(--bi-size-font-m);
      line-height: var(--bi-line-height);
      font-weight: var(--bi-font-weight-regular);
      inline-size: 100%;
      block-size: var(--_bi-textarea-block-size);
      border: var(--bi-size-border-weight) solid
        var(--_bi-textarea-border-color);
      border-radius: var(--bi-size-border-radius-m);
      outline: none;
      transition: border-color var(--bi-transition-duration-fast)
        var(--bi-transition-timing-default);
      background-color: var(--bi-color-background);
      padding: var(--padding-block) var(--bi-size-space-m);
      resize: var(--_bi-textarea-resize, vertical);
      overflow: var(--_bi-textarea-overflow, auto);
    }
    .bi-textarea:focus {
      border-color: var(--_bi-textarea-border-color--focus);
    }
    .bi-textarea::placeholder {
      color: var(--bi-color-text-bright);
    }
    .bi-textarea:not(:disabled) {
      cursor: text;
    }
  `];
