import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --_bi-modal-display: none;
      --_bi-modal-inline-size: var(--bi-size-layout-m);
      --_bi-modal-block-size: fit-content;
      --_bi-modal-content-padding: var(--bi-size-space-m);
      display: none;
    }

    :host([open]) {
      --_bi-modal-display: flex;
      --_bi-modal-animation: fadeScaleIn var(--bi-transition-duration-normal)
        var(--bi-transition-timing-default);
      --_bi-modal-backdrop-animation: fadeIn
        var(--bi-transition-duration-normal) forwards;
      display: block;
    }

    :host([inline-size="xs"]) {
      --_bi-modal-inline-size: var(--bi-size-layout-xs);
    }
    :host([inline-size="s"]) {
      --_bi-modal-inline-size: var(--bi-size-layout-s);
    }
    :host([inline-size="l"]) {
      --_bi-modal-inline-size: var(--bi-size-layout-l);
    }
    :host([inline-size="xl"]) {
      --_bi-modal-inline-size: var(--bi-size-layout-xl);
    }
    :host([inline-size="fill"]) {
      --_bi-modal-inline-size: 100%;
    }

    :host([block-size="fill"]) {
      --_bi-modal-block-size: 100%;
    }

    :host([content-padding="none"]) .bi-modal-content {
      --_bi-modal-content-padding: 0;
    }

    .bi-modal {
      display: var(--_bi-modal-display);
      border: none;
      padding: 0;
      background: var(--bi-color-background);
      border-radius: var(--bi-size-border-radius-m);
      inline-size: var(--_bi-modal-inline-size);
      block-size: var(--_bi-modal-block-size);
      max-block-size: calc(100% - (var(--bi-size-space-m) * 2));
      max-inline-size: calc(100% - (var(--bi-size-space-m) * 2));
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
      animation: var(--_bi-modal-animation, none);
      outline: none;
    }
    .bi-modal::backdrop {
      background: rgba(0, 0, 0, var(--bi-opacity-backdrop));
      animation: var(--_bi-modal-backdrop-animation, none);
    }

    .bi-modal-inner {
      inline-size: 100%;
      display: var(--_bi-modal-display);
      flex-direction: column;
    }

    .bi-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--bi-size-space-s);
      padding-inline-start: var(--bi-size-space-m);
      min-block-size: calc(
        var(--bi-size-control-block-s) + var(--bi-size-border-weight) +
          (var(--bi-size-space-s) * 2)
      );
      gap: var(--bi-size-space-m);
      border-block-end: var(--bi-size-border-weight) solid
        var(--bi-color-border-bright);
      flex: 0 0 auto;
    }

    .bi-modal-header-content {
      flex: 1;
      font-size: var(--bi-size-font-l);
      font-weight: var(--bi-font-weight-semibold);
    }

    .bi-modal-header-close {
      --bi-button-target-increase: var(--bi-size-space-s);
      flex: 0 0 auto;
      align-self: flex-start;
    }

    .bi-modal-header-close-icon {
      block-size: var(--bi-size-space-s);
    }

    .bi-modal-content {
      flex: 1;
      padding: var(--_bi-modal-content-padding);
      overflow: auto;
      overscroll-behavior: contain;
    }

    .bi-modal-footer {
      flex: 0 0 auto;
      padding: var(--bi-size-space-m);
      display: flex;
      justify-content: flex-end;
      gap: var(--bi-size-space-s);
      border-block-start: var(--bi-size-border-weight) solid
        var(--bi-color-border-bright);
    }
    .bi-modal-footer:not(.bi-modal-footer--hasContent) {
      display: none;
    }

    @keyframes fadeScaleIn {
      0% {
        opacity: 0;
        transform: scale(0.95);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  `];
