import { html } from "lit";
export default (className => html`<svg
  class=${className}
  viewBox="0 0 576 512"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
>
  <path
    d="m197 71.2 37.7 14.1 14.1 37.7c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7 37.7-14.1c3-1.1 5-4 5-7.2s-2-6.1-5-7.2l-37.7-14.1-14.1-37.7c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5l-14.1 37.7-37.7 14.1c-3 1.1-5 4-5 7.2s2 6.1 5 7.2z"
  />
  <path
    d="m192 128c0-4.8-3-9.1-7.5-10.8l-56.5-21.2-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5l-21.2 56.5-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8l56.5 21.2 21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5l21.2-56.5 56.5-21.2c4.5-1.7 7.5-6 7.5-10.8z"
  />
  <path
    d="m559.8 280.6-122.4-45.9-45.9-122.4c-3.7-9.8-13-16.2-23.4-16.2s-19.7 6.5-23.4 16.2l-45.9 122.4-122.4 45.9c-9.8 3.7-16.2 13-16.2 23.4s6.5 19.7 16.2 23.4l122.4 45.9 45.9 122.4c3.7 9.8 13 16.2 23.4 16.2s19.7-6.5 23.4-16.2l45.9-122.4 122.4-45.9c9.8-3.7 16.2-13 16.2-23.4s-6.5-19.7-16.2-23.4z"
  />
</svg> `);
