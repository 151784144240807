import { count_occurences } from './helpers.js';
export default function closeBraces(retained_template, template) {
  var placeholder = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'x';
  var empty_placeholder = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ' ';
  var cut_before = retained_template.length;
  var opening_braces = count_occurences('(', retained_template);
  var closing_braces = count_occurences(')', retained_template);
  var dangling_braces = opening_braces - closing_braces;
  while (dangling_braces > 0 && cut_before < template.length) {
    retained_template += template[cut_before].replace(placeholder, empty_placeholder);
    if (template[cut_before] === ')') {
      dangling_braces--;
    }
    cut_before++;
  }
  return retained_template;
}
