var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import cardStyles from "./card.styles";
import "../button/button.component";
import * as tokens from "@mybingli/ds-tokens";
import { classMap } from "lit/directives/class-map.js";
/**
 * A card is a container that has a header and content. It can be collapsible.
 * @slot - The slot to place the card content in.
 * @slot header-title - The slot to place the header title in.
 * @slot header-actions - The slot to place the header actions in.
 * @fires {Event} expanded - Fired when the card has finished expanding.
 * @fires {Event} collapsed - Fired when the card has finished collapsing.
 */
let Card = class Card extends LitElement {
  constructor() {
    super(...arguments);
    /** Makes the card collapsible. */
    this.collapsible = false;
    /** Whether the card is collapsed. */
    this.collapsed = false;
    /** Stretches the card vertically. Use this when the card needs to vertically fill a container, for instance in a grid. */
    this.stretched = false;
    this.hasHeaderActions = false;
  }
  render() {
    const headerToggleHTML = this.collapsible ? html` <bi-button
          class="bi-card-header-toggle"
          square
          size="s"
          variant="plain"
          @click=${this.toggle}
        >
          <svg
            class="bi-card-header-toggle-icon"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-169.4 169.4-169.4-169.3c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
              fill="currentColor"
            />
          </svg>
        </bi-button>` : nothing;
    return html`
      <div
        class=${classMap({
      "bi-card": true,
      "bi-card--hasActions": this.hasHeaderActions || this.collapsible
    })}
      >
        <div class="bi-card-header">
          <div class="bi-card-header-content">
            <slot name="header-title"></slot>
          </div>
          <div class="bi-card-header-actions">
            <slot
              name="header-actions"
              @slotchange=${this.handleHeaderActionsSlotChange}
            ></slot>
            ${headerToggleHTML}
          </div>
        </div>
        <div class="bi-card-content" .inert=${this.collapsed}>
          <div class="bi-card-content-inner">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }
  /** Toggles the card collapsed state. */
  toggle() {
    this.collapsed ? this.expand() : this.collapse();
  }
  /** Expands the card. */
  expand() {
    this.style.setProperty("--_bi-card-block-size", `${this.getCollapsedBlockSize()}px`);
    setTimeout(() => {
      this.collapsed = false;
      this.style.setProperty("--_bi-card-block-size", `${this.getExpandedBlockSize()}px`);
      setTimeout(() => {
        this.style.removeProperty("--_bi-card-block-size");
        this.dispatchEvent(new Event("expanded", {
          bubbles: false,
          composed: true
        }));
      }, parseInt(tokens.biTransitionDurationSlow));
    });
  }
  /** Collapses the card. */
  collapse() {
    this.style.setProperty("--_bi-card-block-size", `${this.getExpandedBlockSize()}px`);
    setTimeout(() => {
      this.collapsed = true;
      this.style.setProperty("--_bi-card-block-size", `${this.getCollapsedBlockSize()}px`);
      setTimeout(() => {
        this.style.removeProperty("--_bi-card-block-size");
        this.dispatchEvent(new Event("collapsed", {
          bubbles: false,
          composed: true
        }));
      }, parseInt(tokens.biTransitionDurationSlow));
    });
  }
  getCardBlockSize() {
    return this.getBoundingClientRect().height;
  }
  getHeaderBlockSize() {
    return this.headerElement.getBoundingClientRect().height;
  }
  getContentBlockSize() {
    return this.contentElement.getBoundingClientRect().height;
  }
  getCollapsedBlockSize() {
    return this.getHeaderBlockSize() + tokens.biSizeBorderWeight * 2;
  }
  getExpandedBlockSize() {
    return Math.max(this.getHeaderBlockSize() + this.getContentBlockSize(), this.getCardBlockSize());
  }
  handleHeaderActionsSlotChange() {
    var _a;
    this.hasHeaderActions = ((_a = this.querySelectorAll("[slot='header-actions']")) === null || _a === void 0 ? void 0 : _a.length) > 0;
  }
};
Card.styles = cardStyles;
__decorate([query(".bi-card-header")], Card.prototype, "headerElement", void 0);
__decorate([query(".bi-card-content-inner")], Card.prototype, "contentElement", void 0);
__decorate([property()], Card.prototype, "variant", void 0);
__decorate([property({
  type: Boolean
})], Card.prototype, "collapsible", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
})], Card.prototype, "collapsed", void 0);
__decorate([property({
  type: Boolean
})], Card.prototype, "stretched", void 0);
__decorate([state()], Card.prototype, "hasHeaderActions", void 0);
Card = __decorate([customElement("bi-card")], Card);
export { Card };
