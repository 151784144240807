import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --bi-popover-min-inline-size: 0;
      --_bi-dropdown-item-outline-color: var(--bi-color-primary-default);
      display: flex;
      inline-size: 100%;
      min-inline-size: 210px;
      max-inline-size: var(--_bi-dropdown-item-max-inline-size, 280px);
    }

    :host([variant="danger"]) {
      --_bi-dropdown-item-color: var(--bi-color-error-shade);
      --_bi-dropdown-item-slot-color: var(--bi-color-error-shade);
      --_bi-dropdown-item-outline-color: var(--bi-color-error-default);
    }

    :host([disabled]) {
      --_bi-dropdown-item-opacity: var(--bi-opacity-disabled);
    }

    .bi-dropdown-item {
      display: flex;
      inline-size: 100%;
    }

    .bi-dropdown-item-button {
      appearance: none;
      outline: none;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--bi-size-space-m);
      inline-size: 100%;
      block-size: var(--bi-size-control-block-m);
      padding: var(--bi-size-space-s) var(--bi-size-space-m);
      font-family: var(--bi-font-family);
      font-size: var(--bi-size-font-m);
      font-weight: var(--bi-font-weight-regular);
      background: var(--_bi-dropdown-item-background-color, none);
      color: var(--_bi-dropdown-item-slot-color, var(--bi-color-text-bright));
      border: none;
      opacity: var(--_bi-dropdown-item-opacity, 1);
      text-align: start;
      justify-content: start;
      box-shadow: 0 0 0 2px transparent;
      border-radius: var(--bi-size-border-radius-m);
      transition: all var(--bi-transition-duration-fast)
        var(--bi-transition-timing-default);
      transition-property: background-color, box-shadow, color;
    }

    .bi-dropdown-item-button:not(:disabled) {
      cursor: pointer;
    }

    .bi-dropdown-item-button:active {
      opacity: var(--bi-opacity-active);
    }

    .bi-dropdown-item-button:not(:disabled):not(
        .bi-dropdown-item-button--insideSelect
      ):hover {
      --_bi-dropdown-item-background-color: var(--bi-color-primary-default);
      --_bi-dropdown-item-color: var(--bi-color-text-on-dark);
      --_bi-dropdown-item-slot-color: var(--bi-color-text-on-dark);
    }

    :host([variant="danger"]) .bi-dropdown-item-button:not(:disabled):hover {
      --_bi-dropdown-item-background-color: var(--bi-color-error-default);
    }

    .bi-dropdown-item-button:focus-visible {
      box-shadow: 0 0 0 var(--bi-size-border-weight) var(--bi-color-background),
        0 0 0 calc(var(--bi-size-border-weight) * 2)
          var(--_bi-dropdown-item-outline-color);
    }

    .bi-dropdown-item-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--_bi-dropdown-item-color, var(--bi-color-text-default));
      flex: 1;
      transition: color var(--bi-transition-duration-fast)
        var(--bi-transition-timing-default);
    }

    .bi-dropdown-item-subIcon {
      transform: rotate(-90deg);
      inline-size: var(--bi-size-space-m);
      vertical-align: middle;
    }

    .bi-dropdown-item-popover {
      &:after {
        content: "";
        position: absolute;
        inset: calc(var(--bi-size-space-m) * -1);
        z-index: -1;
      }
    }

    .bi-dropdown-item-sub {
      padding: var(--bi-size-space-xs);
    }
  `];
