<bi-dropdown #dropdown (click)="dropdown.close()">
  <bi-button
    variant="plain"
    square
    slot="toggle"
    (click)="$event.stopPropagation()"
  >
    <img
      class="profile-img"
      [src]="userImagePath$ | async"
      [alt]="'l_profile_picture' | translate"
    />
  </bi-button>
  <bi-dropdown-item [routerLink]="'/account'">
    <i class="fas fa-cog" slot="start"></i>
    {{ "settings" | translate }}
  </bi-dropdown-item>
  <bi-dropdown-item (click)="showAboutDialog()">
    <i class="fas fa-info-circle" slot="start"></i>
    {{ "about" | translate }}
  </bi-dropdown-item>
  <bi-dropdown-item (click)="logout()">
    <i class="fas fa-arrow-right-from-bracket" slot="start"></i>
    {{ "logout" | translate }}
  </bi-dropdown-item>
</bi-dropdown>
