import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --bi-tree-item-sub-indent: calc(var(--bi-size-space-s) * 2);
      display: var(--_bi-tree-item-display);
      grid-column: span 2;
      grid-template-columns: subgrid;
    }

    .bi-tree-item {
      position: relative;
      inline-size: 100%;
      display: var(--_bi-tree-item-display);
      grid-column: span 2;
      grid-template-columns: subgrid;
      flex-wrap: wrap;
      gap: var(--bi-size-space-xs);
      padding-inline-start: var(--bi-tree-item-sub-indent);
    }
    :host([wrap-content]) .bi-tree-item {
      flex-direction: column;
    }
    .bi-tree-item:before {
      content: "";
      position: absolute;
      inset-inline-start: -1px;
      inset-block-start: calc(
        var(--bi-tree-item-sub-indent) + var(--bi-size-space-xs)
      );
      inset-block-end: 0;
      border-inline-start: var(--bi-size-border-weight) solid
        var(--bi-color-border-bright);
    }

    .bi-tree-item-icon {
      --size: calc(var(--bi-tree-item-sub-indent) + 1px);
      content: "";
      position: absolute;
      aspect-ratio: 1;
      inline-size: var(--size);
      block-size: var(--size);
      inset-inline-start: calc(var(--size) * -0.5);
      inset-block-start: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--bi-color-border-bright);
    }

    .bi-tree-item-label {
      grid-column: 1;
      flex: 0 1 auto;
      font-weight: var(--bi-font-weight-regular);
      max-inline-size: var(--bi-tree-item-label-max-inline-size, none);
      overflow-wrap: anywhere;
      hyphens: auto;
    }
    :host([wrap-content]) .bi-tree-item-label {
      grid-column: 1 / -1;
    }

    .bi-tree-item-content {
      grid-column: 2;
      flex: 1;
      color: var(--bi-color-text-bright);
    }
    :host([wrap-content]) .bi-tree-item-content {
      grid-column: 1 / -1;
      padding-block-end: var(--bi-size-space-xs);
      margin-block-end: calc(var(--bi-size-space-xs) * -1);
    }

    .bi-tree-item-content--empty {
      display: none;
    }

    .bi-tree-item-sub {
      --indent: calc(var(--bi-tree-item-sub-indent) / 2);
      inline-size: calc(100% + var(--indent));
      grid-column: 1 / -1;
      margin-inline-start: calc(var(--bi-tree-item-sub-indent) * -0.5);
    }
    .bi-tree-item-sub--empty {
      display: none;
    }
  `];
