import { css } from "lit";
export default css`
  :host,
  [bi-unscoped] {
    all: unset;
    display: inline-block;
    font-family: var(--bi-font-family);
    font-size: var(--bi-size-font-m);
    font-weight: var(--bi-font-weight-regular);
    line-height: var(--bi-line-height);
    color: var(--bi-color-text-default);
    -webkit-font-smoothing: var(--bi-font-webkit-smoothing);
  }
  :host,
  [bi-unscoped],
  :host *,
  [bi-unscoped] * {
    box-sizing: border-box;
  }
`;
