import { Injectable, inject } from '@angular/core';
import { ClientService } from './client.service';
import { AccountService } from 'src/app/modules/account/account.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  private accountService = inject(AccountService);
  private clientService = inject(ClientService);
  previewToken: string | null = null;
  private previewRefreshTokenLocalStorageKey = 'refresh-token';

  get isPreviewMode() {
    return Boolean(this.previewToken);
  }

  enablePreviewModeWithToken(token: string) {
    this.previewToken = token;
    this.accountService.initializeService();
    this.clientService.initializeService();
  }

  saveRefreshTokenToLocalStorage(refreshToken: string) {
    localStorage.setItem(this.previewRefreshTokenLocalStorageKey, refreshToken);
  }
}
