import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    ::slotted([slot="link"]) {
      position: absolute;
      inset: 0;
      opacity: 0;
    }

    :host {
      cursor: pointer;
      position: relative;
    }

    :host([selected]) {
      --_bi-tab-font-weight: var(--bi-font-weight-semibold);
      box-shadow: inset 0 calc(var(--bi-size-border-weight) * -2) 0 0
        var(--bi-color-primary-default);
    }

    :host(:active) {
      --_bi-tab-opacity: var(--bi-opacity-active);
    }

    .bi-tab {
      display: block;
      position: relative;
      block-size: var(--bi-tab-block-size, var(--bi-size-control-block-m));
      line-height: var(--bi-tab-block-size, var(--bi-size-control-block-m));
      padding-inline: var(--bi-size-space-m);
      font-weight: var(--_bi-tab-font-weight, var(--bi-font-weight-regular));
      opacity: var(--_bi-tab-opacity, 1);
      color: var(--bi-color-text-default);
      text-decoration: none;
    }
    .bi-tab::before {
      content: attr(data-text);
      font-weight: var(--bi-font-weight-semibold);
      display: block;
      block-size: 0;
      visibility: hidden;
    }
    :host(:focus-visible) .bi-tab::after {
      content: "";
      pointer-events: none;
      display: block;
      position: absolute;
      inset: var(--bi-size-space-xs);
      border-radius: var(--bi-size-border-radius-m);
      border: var(--bi-size-border-weight) solid var(--bi-color-primary-default);
    }
  `];
