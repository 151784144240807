import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
import formControlStyles from "../shared/styles/form-control.styles";
export default [baseStyles, formControlStyles, css`
    :host,
    bi-input-unscoped[bi-unscoped] {
      --_bi-input-inline-size: 100%;
      --_bi-input-max-inline-size: var(--bi-input-max-inline-size, 280px);
      --_bi-input-border-color: var(--bi-color-border-default);
      --_bi-input-border-color--focus: var(--bi-color-primary-default);
      --_bi-input-border-start-start-radius: var(--bi-size-border-radius-m);
      --_bi-input-border-start-end-radius: var(--bi-size-border-radius-m);
      --_bi-input-border-end-start-radius: var(--bi-size-border-radius-m);
      --_bi-input-border-end-end-radius: var(--bi-size-border-radius-m);
      --_bi-input-padding-inline-start: var(--bi-size-space-m);
      --_bi-input-padding-inline-end: var(--bi-size-space-m);

      display: flex;
      flex-direction: column;
      inline-size: var(--_bi-input-inline-size);
      max-inline-size: var(--_bi-input-max-inline-size);
      gap: var(--bi-size-space-xs);
    }

    :host([expand]),
    bi-input-unscoped[bi-unscoped][expand] {
      --_bi-input-max-inline-size: none;
    }

    :host([error]),
    bi-input-unscoped[bi-unscoped][error] {
      --_bi-input-border-color: var(--bi-color-error-default);
      --_bi-input-border-color--focus: var(--bi-color-error-shade);
    }

    .bi-input-container {
      inline-size: 100%;
      display: flex;
      position: relative;
    }
    .bi-input-container--hasInnerStart {
      --_bi-input-padding-inline-start: var(
        --_bi-input-inner-start-inline-size
      );
    }
    .bi-input-container--hasInnerEnd {
      --_bi-input-padding-inline-end: var(--_bi-input-inner-end-inline-size);
    }
    .bi-input-container.bi-input-container--hasOuterStart {
      --_bi-input-border-start-start-radius: 0;
      --_bi-input-border-end-start-radius: 0;
    }
    .bi-input-container.bi-input-container--hasOuterEnd {
      --_bi-input-border-start-end-radius: 0;
      --_bi-input-border-end-end-radius: 0;
    }
    .bi-input-container.bi-input-container--hasOuterStart.bi-input-container--hasOuterEnd {
      --_bi-input-border-start-start-radius: 0;
      --_bi-input-border-start-end-radius: 0;
      --_bi-input-border-end-start-radius: 0;
      --_bi-input-border-end-end-radius: 0;
    }

    .bi-input-container-inner {
      flex: 1;
      display: flex;
      position: relative;
    }

    .bi-input {
      font-family: var(--bi-font-family);
      font-size: var(--bi-size-font-m);
      font-weight: var(--bi-font-weight-regular);
      inline-size: 100%;
      block-size: var(--bi-size-control-block-m);
      border: var(--bi-size-border-weight) solid var(--_bi-input-border-color);
      border-start-start-radius: var(--_bi-input-border-start-start-radius);
      border-start-end-radius: var(--_bi-input-border-start-end-radius);
      border-end-start-radius: var(--_bi-input-border-end-start-radius);
      border-end-end-radius: var(--_bi-input-border-end-end-radius);
      outline: none;
      padding-inline-start: var(--_bi-input-padding-inline-start);
      padding-inline-end: var(--_bi-input-padding-inline-end);
      transition: border-color var(--bi-transition-duration-fast)
        var(--bi-transition-timing-default);
      background-color: var(--bi-color-background);
    }
    :host([type="date"]) .bi-input,
    :host([type="datetime-local"]) .bi-input {
      display: grid;
    }
    .bi-input:focus {
      border-color: var(--_bi-input-border-color--focus);
    }
    .bi-input::placeholder {
      color: var(--bi-color-text-bright);
    }
    .bi-input:not(:disabled) {
      cursor: text;
    }
    .bi-input::-webkit-search-cancel-button {
      display: none;
    }
    .bi-input::-webkit-date-and-time-value {
      text-align: left;
      color: var(--bi-color-text-default);
    }

    ::slotted([slot]) {
      flex: 0 0 auto;
      position: relative;
    }

    .bi-input-container:not(.bi-input-container--hasInnerEnd)
      .bi-input-inner-end,
    .bi-input-container:not(.bi-input-container--hasInnerStart)
      .bi-input-inner-start {
      display: none;
    }

    .bi-input-inner-start,
    .bi-input-inner-end {
      position: absolute;
      inset-block-start: var(--bi-size-border-weight);
      inset-block-end: var(--bi-size-border-weight);
      display: flex;
      align-items: center;
      gap: var(--bi-size-space-xs);
      color: var(--bi-color-text-bright);
      pointer-events: none;
    }
    .bi-input-inner-start {
      inset-inline-start: 0;
      padding: 0 var(--bi-size-space-s) 0 var(--bi-size-space-m);
    }
    .bi-input-inner-end {
      inset-inline-end: 0;
      padding: 0 var(--bi-size-space-m) 0 var(--bi-size-space-s);
    }
    .bi-input-inner-end:has(.bi-input-reset) {
      padding-inline-end: var(--bi-size-space-xs);
    }

    slot[name="outer-start"] {
      --_bi-button-border-radius-start-end: 0;
      --_bi-button-border-radius-end-end: 0;
      --_bi-button-border-inline-end-width: 0;
    }

    slot[name="outer-end"] {
      --_bi-button-border-radius-start-start: 0;
      --_bi-button-border-radius-end-start: 0;
      --_button-border-inline-start-width: 0;
    }

    .bi-input-search-icon {
      block-size: var(--bi-size-space-m);
    }

    .bi-input-reset {
      --_bi-button-border-radius-start-end: var(--_bi-button-border-radius);
      --_bi-button-border-radius-end-end: var(--_bi-button-border-radius);
      pointer-events: auto;
    }
    .bi-input-reset:not(:hover) .bi-input-reset-icon {
      color: var(--bi-color-text-bright);
    }
    .bi-input-reset-icon {
      block-size: var(--bi-size-space-s);
      margin-block-start: 2px; // Fine-tuning
    }

    .bi-input-countries {
      max-inline-size: 50%;
      min-inline-size: 120px;
    }
  `];
