var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import treeItemStyles from "./tree-item.styles";
/**
 * An item in a tree.
 * @slot - The slot to place the tree item's label in.
 * @slot content - The slot to place the tree item content in.
 * @slot sub - The slot to place the tree item's subtree in.
 * @slot icon - The slot to place the tree item's icon in.
 * @cssprop [--bi-tree-item-label-max-inline-size = none] - The maximum inline size of the label.
 */
let TreeItem = class TreeItem extends LitElement {
  constructor() {
    super(...arguments);
    /** Whether the content should wrap to its own line.  */
    this.wrapContent = false;
    this.hasContent = false;
    this.hasIcon = false;
    this.hasSub = false;
  }
  render() {
    const contentClasses = classMap({
      "bi-tree-item-content": true,
      "bi-tree-item-content--empty": !this.hasContent
    });
    const subClasses = classMap({
      "bi-tree-item-sub": true,
      "bi-tree-item-sub--empty": !this.hasSub
    });
    const iconHTML = this.hasIcon ? nothing : html`<svg
          enable-background="new 0 0 4 4"
          viewBox="0 0 4 4"
          width="4"
          height="4"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <circle cx="2" cy="2" r="2" />
        </svg>`;
    return html`
      <div class="bi-tree-item" role="treeitem">
        <div class="bi-tree-item-icon">
          ${iconHTML}
          <slot name="icon" @slotchange=${this.handleIconSlotChange}></slot>
        </div>
        <div class="bi-tree-item-label">
          <slot></slot>
        </div>
        <div class=${contentClasses}>
          <slot
            name="content"
            @slotchange=${this.handleContentSlotChange}
          ></slot>
        </div>
        <div class=${subClasses}>
          <slot name="sub" @slotchange=${this.handleSubSlotChange}></slot>
        </div>
      </div>
    `;
  }
  handleContentSlotChange() {
    this.hasContent = this.slottedContentElements.length > 0;
  }
  handleSubSlotChange() {
    this.hasSub = this.slottedSubElements.length > 0;
  }
  handleIconSlotChange() {
    this.hasIcon = this.slottedIconElements.length > 0;
  }
};
TreeItem.styles = treeItemStyles;
__decorate([queryAssignedElements({
  slot: "content",
  selector: "*"
})], TreeItem.prototype, "slottedContentElements", void 0);
__decorate([queryAssignedElements({
  slot: "sub",
  selector: "bi-tree"
})], TreeItem.prototype, "slottedSubElements", void 0);
__decorate([queryAssignedElements({
  slot: "icon"
})], TreeItem.prototype, "slottedIconElements", void 0);
__decorate([property({
  type: Boolean,
  attribute: "wrap-content"
})], TreeItem.prototype, "wrapContent", void 0);
__decorate([state()], TreeItem.prototype, "hasContent", void 0);
__decorate([state()], TreeItem.prototype, "hasIcon", void 0);
__decorate([state()], TreeItem.prototype, "hasSub", void 0);
TreeItem = __decorate([customElement("bi-tree-item")], TreeItem);
export { TreeItem };
