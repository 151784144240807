var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import dropdownStyles from "./dropdown.styles";
import "../popover/popover.component";
import { ResizeController } from "../shared/controllers/resize.controller";
import { styleMap } from "lit/directives/style-map.js";
import { classMap } from "lit/directives/class-map.js";
/**
 * A dropdown displays a list of actions.
 * @slot - The content of the dropdown.
 * @slot toggle - The slot to place the toggle dropdown.
 * @cssprop [--bi-dropdown-max-block-size = none] - The max block size of the dropdown.
 */
let Dropdown = class Dropdown extends LitElement {
  constructor() {
    super(...arguments);
    this.toggleSize = new ResizeController(this, () => this.dropdownElement);
    this.insideSelect = false;
  }
  connectedCallback() {
    super.connectedCallback();
    this.insideSelect = this.closest(".bi-select") !== null;
  }
  render() {
    var _a;
    const classes = classMap({
      "bi-dropdown": true,
      "bi-dropdown--insideSelect": this.insideSelect
    });
    return html`
      <div class=${classes} @keydown=${this.handleKeyDown}>
        <slot
          name="toggle"
          aria-controls="popover"
          @slotchange=${this.handleToggleSlotChange}
        ></slot>
        <bi-popover
          class="bi-dropdown-popover"
          id="popover"
          placement="bottom-start"
          @opened=${this.resetScroll}
          style="${styleMap({
      "--bi-popover-min-inline-size": `${((_a = this.toggleSize.borderBoxSize) === null || _a === void 0 ? void 0 : _a.inlineSize) || 0}px`
    })})}"
        >
          <div class="bi-dropdown-content">
            <slot></slot>
          </div>
        </bi-popover>
      </div>
    `;
  }
  /** Closes the dropdown programmatically. */
  close() {
    this.popoverElement.close();
  }
  /** Opens the dropdown programmatically. */
  open() {
    this.popoverElement.open();
  }
  /** Toggles the dropdown programmatically. */
  toggle() {
    this.popoverElement.toggle();
  }
  handleToggleSlotChange() {
    if (this.querySelector("[expand]") !== null) {
      this.setAttribute("expand", "");
    } else {
      this.removeAttribute("expand");
    }
  }
  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }
  resetScroll() {
    setTimeout(() => {
      this.dropdownContentElement.scrollTop = 0;
    });
  }
};
Dropdown.styles = dropdownStyles;
__decorate([query("bi-popover")], Dropdown.prototype, "popoverElement", void 0);
__decorate([query(".bi-dropdown")], Dropdown.prototype, "dropdownElement", void 0);
__decorate([query(".bi-dropdown-content")], Dropdown.prototype, "dropdownContentElement", void 0);
Dropdown = __decorate([customElement("bi-dropdown")], Dropdown);
export { Dropdown };
