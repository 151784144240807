import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit } from '@angular/core'

import { AccountService } from 'src/app/modules/account/account.service'
import { ClientService } from '../../services/client.service'
import { LanguageService } from '@shared/services/language.service'
import { Observable, take } from 'rxjs'
import { Router, RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { LanguageMenuComponent } from '@mybingli/language-picker'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { UserMenuComponent } from '../user-menu/user-menu.component'
import { BingliAuthService } from '@mybingli/security-service'

@Component({
  standalone: true,
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  imports: [NgIf, NgFor, AsyncPipe, RouterModule, TranslateModule, LanguageMenuComponent, UserMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavigationComponent implements OnInit {
  private clientService = inject(ClientService)
  private accountService = inject(AccountService)
  private languageService = inject(LanguageService)
  private router = inject(Router)
  private bingliAuthService = inject(BingliAuthService)

  availableHcs = this.clientService.availableHcs
  currentHcsOption = this.clientService.currentHcsOption
  currentLanguage$: Observable<string> = this.languageService.currentLanguage$
  navItems = [
    { route: '/interviews', label: 'interviews' },
    { route: '/patients', label: 'l_patients' }
  ]

  ngOnInit() {
    this.bingliAuthService
      .isAuthorizedFor(['TenantAdmin', 'AnalyticsRead'])
      .pipe(take(1))
      .subscribe(isAuthorized => {
        if (isAuthorized) {
          this.navItems.push({ route: '/admin', label: 'l_admin' })
        }
      })
  }

  handleHcsChange(event: Event) {
    const healthCareServiceId = (event.target as HTMLSelectElement).value as unknown as string
    const hcsOption = this.availableHcs().find(hcs => hcs.healthCareServiceId === healthCareServiceId)
    if (hcsOption) {
      this.clientService.changeHcs(hcsOption)
    }
  }

  handleTabSelected(route: string) {
    this.router.navigate([route])
  }

  public handleLanguageChange(language: string) {
    this.accountService.updateLanguagePreference({ language })
  }
}
