var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, nothing } from "lit";
import { customElement, property, query, queryAssignedElements, state } from "lit/decorators.js";
import { MutationController } from "@lit-labs/observers/mutation-controller.js";
import selectStyles from "./select.styles";
import "../dropdown/dropdown.component";
import "../dropdown-item/dropdown-item.component";
import "../button/button.component";
import { FormControl } from "../shared/components/form-control/form-control.component";
import { ifDefined } from "lit/directives/if-defined.js";
import { classMap } from "lit/directives/class-map.js";
import "../input/input.component";
/**
 * Select represents a form control that provides a list of options. For a menu, use Dropdown instead.
 * @slot - The options of the select. Must be `<option>` elements.
 * @slot label-end - The content to show after the label. Useful for a tooltip toggle, for example.
 * @cssprop [--bi-select-dropdown-max-inline-size = var(--bi-size-layout-xxs)] - The maximum inline size of the select dropdown.
 */
let Select = class Select extends FormControl {
  constructor() {
    super();
    /** Whether the select expands to fit its container in an inline direction. */
    this.expand = false;
    /** The size of the select toggle. */
    this.size = "m";
    /** Whether the dropdown has a search input to filter the options. */
    this.searchable = false;
    /** The placeholder of the search input. */
    this.searchPlaceholder = "Search";
    this.options = [];
    this.formControlType = "select";
    this.searchQuery = "";
    this.canHover = window.matchMedia("(hover: hover)").matches;
    this.selectReady = false;
    new MutationController(this, {
      config: {
        characterData: true,
        subtree: true,
        childList: true
      },
      callback: () => this.setOptions()
    });
  }
  firstUpdated() {
    this.setOptions();
  }
  render() {
    var _a, _b;
    return html`
      <slot @slotchange=${this.setOptions}></slot>
      ${this.getLabelsHTML()}
      <div class="bi-select">
        <select
          id=${this.randomId}
          aria-label=${ifDefined(this.label)}
          class="bi-select-control"
          ?disabled=${this.disabled}
          ?required=${this.required}
          tabindex=${ifDefined(this.canHover ? "-1" : undefined)}
          @input=${this.handleInput}
          @change=${this.handleChange}
        >
          ${this.getSelectOptionsHTML()}
        </select>
        <bi-dropdown
          class="bi-select-dropdown"
          aria-hidden="true"
          tabindex=${ifDefined(!this.canHover ? "-1" : undefined)}
          @keydown=${this.handleDropdownKeydown}
          @opened=${this.handleDropdownOpened}
          @closed=${this.handleDropdownClosed}
        >
          <bi-button
            class="bi-select-dropdown-toggle"
            slot="toggle"
            ?expand=${this.expand}
            ?disabled=${this.disabled}
            justify-content="space-between"
            variant=${ifDefined(this.error ? "danger-outline" : undefined)}
            size=${this.size}
            title=${ifDefined((_a = this.getSelectedOption()) === null || _a === void 0 ? void 0 : _a.label)}
            >${(_b = this.getSelectedOption()) === null || _b === void 0 ? void 0 : _b.label}
          </bi-button>
          <div class="bi-select-dropdown-content">
            ${this.getSearchInputHTML()} ${this.getDropdownOptionsHTML()}
          </div>
        </bi-dropdown>
      </div>
      ${this.getErrorHTML()}
    `;
  }
  getSearchInputHTML() {
    if (!this.searchable) return nothing;
    return html`<div class="bi-select-search">
      <bi-input
        class="bi-select-search-input"
        placeholder=${this.searchPlaceholder}
        type="search"
        expand
        @input=${this.handleSearchInput}
      ></bi-input>
    </div>`;
  }
  handleSearchInput(event) {
    const value = event.target.value;
    this.searchQuery = value;
    this.setOptions();
  }
  handleDropdownOpened() {
    // This makes sure that the dropdown can be opened with a quick click without selecting an option on mouseup.
    this.selectReady = false;
    if (this.selectReadyTimeout) {
      clearTimeout(this.selectReadyTimeout);
    }
    this.selectReadyTimeout = setTimeout(() => this.selectReady = true, 200);
    if (this.value) {
      this.setDropdownOptionHovered(this.value, true);
    }
    if (this.searchable) {
      setTimeout(() => {
        this.searchInputElement.focus();
      });
    }
  }
  handleDropdownClosed() {
    if (this.searchable) {
      this.searchInputElement.value = "";
      this.searchQuery = "";
      this.setOptions();
    }
    this.dropdownToggleElement.focus();
    this.options.forEach(option => option.hovered = false);
    this.requestUpdate();
  }
  handleDropdownKeydown(e) {
    if (["ArrowDown", "ArrowUp"].includes(e.key)) {
      e.preventDefault();
    }
    if (this.dropdownElement.popoverElement.opened) {
      const hoveredOptionIndex = this.options.findIndex(option => option.hovered);
      if (e.key === "ArrowDown") {
        const nextOption = this.options.find((option, index) => index > hoveredOptionIndex && !option.disabled);
        if (nextOption) {
          this.setDropdownOptionHovered(nextOption.value, true);
        }
      } else if (e.key === "ArrowUp") {
        let previousOption = this.options.filter((option, index) => index < hoveredOptionIndex && !option.disabled).reverse()[0];
        if (previousOption) {
          this.setDropdownOptionHovered(previousOption.value, true);
        }
      } else if (["Enter", " "].includes(e.key) && hoveredOptionIndex !== -1) {
        this.selectDropdownOption(this.options[hoveredOptionIndex].value);
      }
    } else if (["ArrowDown", "ArrowUp", "Enter", " "].includes(e.key)) {
      this.dropdownElement.open();
      if (this.value) {
        this.setDropdownOptionHovered(this.value, true);
        return;
      }
      if (e.key === "ArrowDown") {
        const firstOption = this.options.find(option => !option.disabled);
        if (firstOption) {
          this.setDropdownOptionHovered(firstOption.value, true);
        }
      } else if (e.key === "ArrowUp") {
        const lastOption = this.options.filter(option => !option.disabled).reverse()[0];
        if (lastOption) {
          this.setDropdownOptionHovered(lastOption.value, true);
        }
      }
    }
  }
  setDropdownOptionHovered(value, scrollIntoView = false) {
    this.options.forEach(option => option.hovered = option.value === value);
    this.requestUpdate();
    if (scrollIntoView) {
      setTimeout(() => {
        var _a;
        (_a = this.dropdownElement.querySelector(`[data-value="${value}"]`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
          block: "nearest",
          inline: "nearest"
        });
      });
    }
  }
  setOptions() {
    this.options = this.assignedOptions.map(option => {
      var _a;
      return {
        value: option.value,
        label: (_a = option.textContent) !== null && _a !== void 0 ? _a : "",
        disabled: option.disabled
      };
    }).filter(option => this.searchQuery ? option.label.toLowerCase().includes(this.searchQuery.toLowerCase()) : true);
  }
  getSelectedOption() {
    return this.assignedOptions.find(option => option.value === this.value);
  }
  getSelectOptionsHTML() {
    return this.options.map(option => html`<option
          value=${option.value}
          ?disabled=${option.disabled}
          ?selected=${option.value === this.value}
        >
          ${option.label}
        </option>`);
  }
  getDropdownOptionsHTML() {
    const options = this.options.map(option => html`<bi-dropdown-item
        data-value=${option.value}
        @mouseup=${() => this.selectReady && this.selectDropdownOption(option.value)}
        @mouseover=${() => !option.disabled && this.setDropdownOptionHovered(option.value)}
        ?disabled=${option.disabled}
        class=${classMap({
      "bi-select-dropdown-item": true,
      "bi-select-dropdown-item--hovered": !!option.hovered
    })}
        tabindex="-1"
        title=${option.label.trim()}
        >${option.label} ${this.getDropdownOptionSelectedIconHTML(option)}
      </bi-dropdown-item>`);
    return options.length ? html`<div class="bi-select-dropdown-items">${options}</div>` : nothing;
  }
  getDropdownOptionSelectedIconHTML(option) {
    return this.getSelectedOption() === option && !option.disabled ? html`<svg
          slot="end"
          class="bi-select-dropdown-item-icon-inner"
          viewBox="0 0 448 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l105.4 105.3 233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z"
          />
        </svg>` : nothing;
  }
  selectDropdownOption(value) {
    this.setValue(value);
    setTimeout(() => {
      this.dropdownElement.close();
    });
  }
  getLabelsHTML() {
    return this.label || this.sublabel ? html`<div class="bi-select-label">
          ${this.getLabelHTML()} ${this.getSublabelHTML()}
        </div>` : nothing;
  }
};
Select.styles = selectStyles;
__decorate([query("bi-dropdown")], Select.prototype, "dropdownElement", void 0);
__decorate([query(".bi-select-dropdown-toggle")], Select.prototype, "dropdownToggleElement", void 0);
__decorate([query(".bi-select-search-input")], Select.prototype, "searchInputElement", void 0);
__decorate([queryAssignedElements({
  selector: "option"
})], Select.prototype, "assignedOptions", void 0);
__decorate([property({
  type: Boolean
})], Select.prototype, "expand", void 0);
__decorate([property()], Select.prototype, "size", void 0);
__decorate([property({
  type: Boolean
})], Select.prototype, "searchable", void 0);
__decorate([property({
  attribute: "search-placeholder"
})], Select.prototype, "searchPlaceholder", void 0);
__decorate([state()], Select.prototype, "options", void 0);
Select = __decorate([customElement("bi-select")], Select);
export { Select };
