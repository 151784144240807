var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import buttonStyles from "./button-group.styles";
/**
 * Groups buttons together. Useful for creating toolbars or menus.
 * @slot - The content of the button group.
 */
let ButtonGroup = class ButtonGroup extends LitElement {
  constructor() {
    super(...arguments);
    /** Whether the buttons are stacked vertically or horizontally. */
    this.direction = "row";
    /** Whether the group expands to fit its container in an inline direction. */
    this.expand = false;
  }
  updated(changedProperties) {
    if (changedProperties.has("expand") || changedProperties.has("direction")) {
      this.updateExpand();
    }
  }
  render() {
    return html`<div class="bi-button-group">
      <slot @slotchange=${this.updateExpand}></slot>
    </div>`;
  }
  updateExpand() {
    const buttons = this.querySelectorAll("bi-button");
    buttons.forEach(button => {
      if (this.expand || this.direction === "column") {
        button.setAttribute("expand", "");
      } else {
        button.removeAttribute("expand");
      }
    });
  }
};
ButtonGroup.styles = buttonStyles;
__decorate([property()], ButtonGroup.prototype, "direction", void 0);
__decorate([property({
  type: Boolean
})], ButtonGroup.prototype, "expand", void 0);
ButtonGroup = __decorate([customElement("bi-button-group")], ButtonGroup);
export { ButtonGroup };
