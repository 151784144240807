import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --_bi-card-content-padding: var(
        --bi-card-content-padding,
        var(--bi-size-space-m)
      );
      --_bi-card-header-background-color: var(
        --bi-card-header-background-color,
        var(--bi-color-neutral-faint)
      );
      --_bi-card-header-color: var(
        --bi-card-header-color,
        var(--bi-color-text-default)
      );
      --_bi-card-border-color: var(
        --bi-card-border-color,
        var(--bi-color-border-default)
      );
      --_bi-card-block-size: auto;
      display: block;
    }

    :host([stretched]:not([collapsed])) {
      --_bi-card-block-size: 100%;
    }

    :host([variant="primary"]) {
      --_bi-card-header-background-color: var(
        --bi-card-header-background-color,
        var(--bi-color-primary-faint)
      );
      --_bi-card-header-color: var(
        --bi-card-header-color,
        var(--bi-color-primary-shade)
      );
      --_bi-card-border-color: var(
        --bi-card-border-color,
        var(--bi-color-primary-default)
      );
    }

    :host([variant="warning"]) {
      --_bi-card-header-background-color: var(
        --bi-card-header-background-color,
        var(--bi-color-warning-faint)
      );
      --_bi-card-header-color: var(
        --bi-card-header-color,
        var(--bi-color-warning-default)
      );
      --_bi-card-border-color: var(
        --bi-card-border-color,
        var(--bi-color-warning-bright)
      );
    }

    :host([variant="danger"]) {
      --_bi-card-header-background-color: var(
        --bi-card-header-background-color,
        var(--bi-color-error-faint)
      );
      --_bi-card-header-color: var(
        --bi-card-header-color,
        var(--bi-color-error-default)
      );
      --_bi-card-border-color: var(
        --bi-card-border-color,
        var(--bi-color-error-bright)
      );
    }

    .bi-card {
      display: flex;
      flex-direction: column;
      border: var(--bi-size-border-weight) solid var(--_bi-card-border-color);
      border-radius: var(--bi-size-border-radius-m);
      block-size: var(--_bi-card-block-size);
      background-color: var(--bi-color-background);
      transition: block-size var(--bi-transition-duration-slow)
        var(--bi-transition-timing-default);
      overflow: hidden;
    }

    .bi-card-header {
      position: relative;
      display: flex;
      align-items: center;
      gap: var(--bi-size-space-m);
      padding: var(--bi-size-space-xs);
      padding-inline-start: var(--bi-size-space-m);
      background-color: var(--_bi-card-header-background-color);
      color: var(--_bi-card-header-color);
      box-shadow: 0 var(--bi-size-border-weight) 0 var(--_bi-card-border-color);
      min-block-size: calc(
        var(--bi-size-control-block-s) + 2 * var(--bi-size-space-xs)
      );
    }

    .bi-card-header-content {
      overflow: hidden;
      font-size: var(--bi-size-font-m);
      font-weight: var(--bi-font-weight-semibold);
    }

    .bi-card-header-content ::slotted(*) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .bi-card-header-actions {
      --bi-button-background-color--hover: rgba(0, 0, 0, 0.05);
      --bi-button-color: var(--_bi-card-header-color);
      display: flex;
      gap: var(--bi-size-space-xxs);
    }
    .bi-card:not(.bi-card--hasActions) .bi-card-header-actions {
      display: none;
    }

    .bi-card-header-toggle {
      display: flex;
      align-items: center;
    }

    .bi-card-header-toggle-icon {
      inline-size: var(--bi-size-space-s);
      vertical-align: middle;
      transition: transform var(--bi-transition-duration-normal)
        var(--bi-transition-timing-default);
      margin-block-start: -2px;
      color: var(--_bi-card-header-color);
    }
    :host([collapsed]) .bi-card-header-toggle-icon {
      transform: rotate(-90deg);
    }

    .bi-card-header-content {
      flex: 1;
    }

    :host([collapsed]) .bi-card-content {
      block-size: 0;
    }
    .bi-card-content-inner {
      padding: var(--_bi-card-content-padding);
    }
  `];
