import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --_bi-tree-item-display: flex;
      display: block;
    }

    .bi-tree {
      display: flex;
      flex-direction: column;
      gap: var(--bi-size-space-xs);
      padding-inline-start: var(--bi-size-space-s);
    }

    @supports (grid-template-columns: subgrid) {
      :host([align-content]) {
        --_bi-tree-item-display: grid;
      }

      :host([align-content]) .bi-tree {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: var(--bi-size-space-s);
        row-gap: var(--bi-size-space-xs);
      }
    }
  `];
