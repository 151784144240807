var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import tabPanelStyles from "./tab-panel.styles";
/**
 * A single tab panel in a tab group.
 * @slot - The slot to place the tab panel content in.
 * @cssprop [--bi-tab-panel-padding = var(--bi-size-space-m)] - The padding of the tab panel.
 */
let TabPanel = class TabPanel extends LitElement {
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "tabpanel");
  }
  render() {
    return html`
      <div class="bi-tab-panel">
        <slot></slot>
      </div>
    `;
  }
};
TabPanel.styles = tabPanelStyles;
TabPanel = __decorate([customElement("bi-tab-panel")], TabPanel);
export { TabPanel };
