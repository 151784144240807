var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import inputStyles from "./input.styles";
import { FormControl } from "../shared/components/form-control/form-control.component";
import { ifDefined } from "lit/directives/if-defined.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { ResizeController } from "../shared/controllers/resize.controller";
import { cleanValue } from "../shared/utils/clean-input-value.util";
import "../button/button.component";
import "../select/select.component";
import { parseDigit, onChange, onKeyDown } from "input-format";
import { AsYouType, parsePhoneNumber, isPossiblePhoneNumber, formatIncompletePhoneNumber } from "libphonenumber-js";
import { getAllCountries, getCountryForTimezone } from "countries-and-timezones";
/**
 * A single-line text input.
 * @prop { boolean } telValid - Whether the phone number is valid.
 * @cssprop [--bi-input-max-inline-size = 280px] - The maximum inline size of the input.
 * @slot outer-start - The content to display before the input. Useful for buttons.
 * @slot inner-start - The content to display inside and at the start of the input. Useful for icons and units. A search icon is added automatically when the type is `search`.
 * @slot inner-end - The content to display inside and at the end of the input. Useful for icons and units.
 * @slot outer-end - The content to display after the input. Useful for buttons.
 * @slot label-end - The content to show after the label. Useful for a tooltip toggle, for example.
 * @fires {Event} telValidated - Fired when the phone number has been validated.
 */
let Input = class Input extends FormControl {
  constructor() {
    var _a;
    super(...arguments);
    /** The type of the input. */
    this.type = "text";
    /** Whether the input expands to fit its container in an inline direction. */
    this.expand = false;
    /** Whether the input is resettable. True by default when the type is `search`. */
    this.resettable = false;
    /** The autocomplete attribute of the input. */
    this.autocomplete = "off";
    /** A map of country names to display in the country select. The keys should be in the following format: `country_united_states_of_america`. */
    this.countries = {};
    this.hasOuterStart = false;
    this.hasInnerStart = false;
    this.hasOuterEnd = false;
    this.hasInnerEnd = false;
    this.innerStartSize = new ResizeController(this, () => this.innerStartElement);
    this.innerEndSize = new ResizeController(this, () => this.innerEndElement);
    this.telValid = false;
    this.phoneNumberInputted = false;
    this.countryCodeDetected = (_a = getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)) === null || _a === void 0 ? void 0 : _a.id;
  }
  firstUpdated() {
    if (this.type === "number") {
      this.disallowPattern = "[^0-9]";
    }
  }
  willUpdate(changedProperties) {
    if (changedProperties.has("type")) {
      if (this.type === "search") {
        this.resettable = true;
      } else if (changedProperties.get("type") === "search") {
        this.resettable = false;
      }
    }
    if (changedProperties.has("value")) {
      if (this.type === "tel") {
        if (this.value && isPossiblePhoneNumber(this.value)) {
          this.phoneNumber = parsePhoneNumber(this.value);
        } else {
          this.phoneNumber = undefined;
        }
        this.setCountryCode();
        this.setTelValidity();
      }
    }
  }
  updated() {
    var _a;
    if (this.type === "tel") {
      if (!this.phoneNumberInputted) {
        this.control.value = ((_a = this.phoneNumber) === null || _a === void 0 ? void 0 : _a.formatNational()) || formatIncompletePhoneNumber(this.value);
      } else {
        this.phoneNumberInputted = false;
      }
    } else {
      this.control.value = this.value;
    }
  }
  render() {
    var _a, _b;
    const isNumber = this.type === "number";
    const inputHTML = html`<input
      id=${this.randomId}
      class="bi-form-control bi-input"
      type=${isNumber ? "text" : this.type}
      inputmode=${isNumber ? "numeric" : nothing}
      name=${ifDefined(this.controlName)}
      min=${ifDefined(this.min)}
      max=${ifDefined(this.max)}
      step=${ifDefined(this.step)}
      placeholder=${ifDefined(this.placeholder)}
      autocomplete=${this.autocomplete}
      pattern=${ifDefined(isNumber ? "[0-9]*" : undefined)}
      ?disabled=${this.disabled}
      ?required=${this.required}
      @input=${this.handleInput}
      @change=${this.handleChange}
      @keydown=${this.handleKeyDown}
    />`;
    return html`${this.getLabelHTML()}
      <div
        class=${classMap({
      "bi-input-container": true,
      "bi-input-container--hasOuterStart": this.hasOuterStart || this.type === "tel",
      "bi-input-container--hasOuterEnd": this.hasOuterEnd,
      "bi-input-container--hasInnerStart": this.hasInnerStart || this.type === "search",
      "bi-input-container--hasInnerEnd": this.hasInnerEnd || this.resettable
    })}
        style=${styleMap({
      "--_bi-input-inner-start-inline-size": `${((_a = this.innerStartSize.borderBoxSize) === null || _a === void 0 ? void 0 : _a.inlineSize) || 0}px`,
      "--_bi-input-inner-end-inline-size": `${((_b = this.innerEndSize.borderBoxSize) === null || _b === void 0 ? void 0 : _b.inlineSize) || 0}px`
    })}
      >
        <slot name="outer-start" @slotchange=${this.handleSlotOuterStartChange}
          >${this.getCountriesHTML()}</slot
        >
        <div class="bi-input-container-inner">
          <div class="bi-input-inner-start">
            ${this.getSearchIconHTML()}
            <slot
              name="inner-start"
              @slotchange=${this.handleSlotInnerStartChange}
            ></slot>
          </div>
          ${inputHTML}
          <div class="bi-input-inner-end">
            <slot
              name="inner-end"
              @slotchange=${this.handleSlotInnerEndChange}
            ></slot>
            ${this.getResetButtonHTML()}
          </div>
        </div>
        <slot
          name="outer-end"
          @slotchange=${this.handleSlotOuterEndChange}
        ></slot>
      </div>
      ${this.getSublabelHTML()} ${this.getErrorHTML()}`;
  }
  getResetButtonHTML() {
    return this.value && (this.resettable || this.type === "search") ? html`<bi-button
          class="bi-input-reset"
          variant="plain"
          size="s"
          square
          @click=${this.reset}
          ><svg
            class="bi-input-reset-icon"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path
              d="m496.8 87.4c20-20 20-52.5 0-72.4-20-20-52.5-20-72.4 0l-168.4 168.6-168.6-168.4c-20-20-52.5-20-72.4 0s-20 52.5 0 72.4l168.6 168.4-168.4 168.6c-20 20-20 52.5 0 72.4 20 20 52.5 20 72.4 0l168.4-168.6 168.6 168.4c20 20 52.5 20 72.4 0 20-20 20-52.5 0-72.4l-168.6-168.4z"
            />
          </svg>
        </bi-button>` : nothing;
  }
  getSearchIconHTML() {
    return this.type === "search" ? html`<svg
          class="bi-input-search-icon"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path
            d="m416 208c0 45.9-14.9 88.3-40 122.7l126.6 126.7c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-126.6-126.7c-34.4 25.2-76.8 40-122.7 40-114.9 0-208-93.1-208-208s93.1-208 208-208 208 93.1 208 208zm-208 144a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
          />
        </svg>` : nothing;
  }
  reset() {
    this.setValue(this.type === "number" ? `${this.min || 0}` : "");
    this.focus();
  }
  handleSlotOuterStartChange() {
    this.hasOuterStart = this.querySelectorAll("[slot='outer-start']").length > 0;
  }
  handleSlotInnerStartChange() {
    this.hasInnerStart = this.querySelectorAll("[slot='inner-start']").length > 0;
  }
  handleSlotOuterEndChange() {
    this.hasOuterEnd = this.querySelectorAll("[slot='outer-end']").length > 0;
  }
  handleSlotInnerEndChange() {
    this.hasInnerEnd = this.querySelectorAll("[slot='inner-end']").length > 0;
  }
  handleKeyDown(e) {
    if (this.type === "number" && ["ArrowDown", "ArrowUp"].includes(e.key)) {
      e.preventDefault();
      const step = this.step || 1;
      let value = Number(this.value);
      if (e.key === "ArrowDown") {
        value = this.min !== undefined ? Math.max(value - step, this.min) : value - step;
      } else if (e.key === "ArrowUp") {
        value = this.max !== undefined ? Math.min(value + step, this.max) : value + step;
      }
      this.setValue(`${value}`);
    } else if (this.type === "tel") {
      this.handlePhoneKeyDown(e);
    }
  }
  handleInput(event) {
    if (this.type === "tel") {
      this.handlePhoneInput(event, true);
    } else {
      if (this.disallowPattern) {
        const target = event.target;
        cleanValue(target, new RegExp(this.disallowPattern, "g"));
      }
      super.handleInput(event);
    }
  }
  /**
   * Moves the focus to the input.
   * @param {FocusOptions} options An optional object for controlling aspects of the focusing process
   */
  focus(options) {
    this.control.focus(options);
  }
  /**
   * Removes the focus from the input.
   */
  blur() {
    this.control.blur();
  }
  getCountriesHTML() {
    return this.type === "tel" ? html`<div class="bi-input-countries">
          <bi-select
            searchable
            expand
            @input=${this.handleCountryInput}
            @change=${this.handleCountryChange}
            value=${ifDefined(this.countryCode)}
          >
            <option value="" disabled selected hidden>Country</option>
            ${Object.values(getAllCountries()).sort((a, b) => a.name.localeCompare(b.name)).map(telCountry => html`<option value="${telCountry.id}">
                  ${this.countries[`country_${telCountry.name.toLowerCase().replaceAll(" ", "_")}`] || telCountry.name}
                </option>`)}
          </bi-select>
        </div>` : nothing;
  }
  handleCountryInput(event) {
    event.stopPropagation();
  }
  handleCountryChange(event) {
    event.stopPropagation();
    this.countryCode = event.target.value;
    this.handlePhoneInput(event);
  }
  handlePhoneKeyDown(event, resetCountryCode = true) {
    this.phoneNumberInputted = true;
    onKeyDown(event, this.control, (character, value) => this.parsePhoneValueCharacter(character, value), value => this.formatPhoneValue(value), value => this.handlePhoneValueChange(value, resetCountryCode));
  }
  handlePhoneInput(event, resetCountryCode = false) {
    this.phoneNumberInputted = true;
    onChange(event, this.control, (character, value) => this.parsePhoneValueCharacter(character, value), value => this.formatPhoneValue(value), value => this.handlePhoneValueChange(value, resetCountryCode));
  }
  parsePhoneValueCharacter(character, value) {
    // Leading plus is allowed
    if (character === "+") {
      if (!value) {
        return character;
      }
    }
    // Digits are allowed
    return parseDigit(character);
  }
  formatPhoneValue(value) {
    const asYouType = new AsYouType(this.countryCode);
    const text = asYouType.input(value || "");
    const template = asYouType.getTemplate();
    return {
      text,
      template
    };
  }
  handlePhoneValueChange(value, resetCountryCode = false) {
    var _a;
    const asYouType = new AsYouType(this.countryCode);
    asYouType.input(value);
    this.phoneNumber = asYouType.getNumber();
    if (resetCountryCode) {
      this.setCountryCode();
    }
    this.setTelValidity();
    this.setValue(((_a = this.phoneNumber) === null || _a === void 0 ? void 0 : _a.number) || value);
  }
  setCountryCode() {
    var _a;
    this.countryCode = ((_a = this.phoneNumber) === null || _a === void 0 ? void 0 : _a.country) || this.countryCode || this.countryCodeDetected;
  }
  setTelValidity() {
    var _a, _b;
    const phoneCountryMatchesSelection = ((_a = this.phoneNumber) === null || _a === void 0 ? void 0 : _a.country) === this.countryCode;
    this.telValid = phoneCountryMatchesSelection && ((_b = this.phoneNumber) === null || _b === void 0 ? void 0 : _b.isValid()) || false;
    this.dispatchEvent(new Event("telValidated", {
      bubbles: false,
      composed: true
    }));
  }
};
Input.styles = inputStyles;
__decorate([property()], Input.prototype, "placeholder", void 0);
__decorate([property()], Input.prototype, "type", void 0);
__decorate([property({
  type: Boolean
})], Input.prototype, "expand", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
})], Input.prototype, "resettable", void 0);
__decorate([property()], Input.prototype, "autocomplete", void 0);
__decorate([property({
  converter: {
    fromAttribute: value => value && !isNaN(+value) ? Number(value) : String(value),
    toAttribute: value => String(value)
  }
})], Input.prototype, "min", void 0);
__decorate([property({
  converter: {
    fromAttribute: value => value && !isNaN(+value) ? Number(value) : String(value),
    toAttribute: value => String(value)
  }
})], Input.prototype, "max", void 0);
__decorate([property({
  type: Number
})], Input.prototype, "step", void 0);
__decorate([property({
  reflect: true,
  attribute: "disallow-pattern"
})], Input.prototype, "disallowPattern", void 0);
__decorate([property({
  attribute: "country-code"
})], Input.prototype, "countryCode", void 0);
__decorate([property({
  type: Object
})], Input.prototype, "countries", void 0);
__decorate([query(".bi-input-inner-start")], Input.prototype, "innerStartElement", void 0);
__decorate([query(".bi-input-inner-end")], Input.prototype, "innerEndElement", void 0);
__decorate([state()], Input.prototype, "hasOuterStart", void 0);
__decorate([state()], Input.prototype, "hasInnerStart", void 0);
__decorate([state()], Input.prototype, "hasOuterEnd", void 0);
__decorate([state()], Input.prototype, "hasInnerEnd", void 0);
Input = __decorate([customElement("bi-input")], Input);
export { Input };
