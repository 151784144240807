import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --_bi-button-group-direction: row;
      --_bi-button-group-slotted-flex: 0 0 auto;
    }

    :host([expand]) {
      inline-size: 100%;
      --_bi-button-group-slotted-flex: 1;
    }

    :host([direction="column"]) {
      --_bi-button-group-direction: column;
      --_bi-button-group-slotted-flex: 1;
    }

    .bi-button-group {
      display: flex;
      flex-direction: var(--_bi-button-group-direction);
    }

    ::slotted(bi-button) {
      flex: var(--_bi-button-group-slotted-flex);
    }

    ::slotted(bi-button[pressed]) {
      --bi-button-position: relative;
      --bi-button-z-index: 1;
    }

    :host(:not([direction="column"])) ::slotted(bi-button:not(:first-child)) {
      --_bi-button-border-radius-start-start: 0;
      --_bi-button-border-radius-end-start: 0;
    }
    :host(:not([direction="column"])) ::slotted(bi-button:not(:last-child)) {
      --_bi-button-border-radius-end-end: 0;
      --_bi-button-border-radius-start-end: 0;
      --_bi-button-inline-size-correction: var(--bi-size-border-weight);
      margin-inline-end: calc(var(--bi-size-border-weight) * -1);
    }

    :host([direction="column"]) ::slotted(bi-button:not(:first-child)) {
      --_bi-button-border-radius-start-start: 0;
      --_bi-button-border-radius-start-end: 0;
    }
    :host([direction="column"]) ::slotted(bi-button:not(:last-child)) {
      --_bi-button-border-radius-end-end: 0;
      --_bi-button-border-radius-end-start: 0;
      --_bi-button-block-size-correction: var(--bi-size-border-weight);
      margin-block-end: calc(var(--bi-size-border-weight) * -1);
    }
  `];
