import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { TranslationService } from '../services/translation.service';
import { Resolved } from '../models/resolved.model';

export const supportedLanguagesResolver: ResolveFn<
  Observable<Resolved<string[]>>
> = () =>
  inject(TranslationService)
    .getSupportedLanguages()
    .pipe(
      map((response) => ({ data: response.data })),
      catchError((error) =>
        of({ error: { status: error.status, message: error.message } })
      )
    );
