var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import modalStyles from "./modal.styles";
import "../button/button.component";
/**
 * Displays a modal dialog on top of other page content.
 * @slot - The slot to place the modal content in.
 * @slot header-title - The slot to place the modal header title in.
 * @slot footer - The slot to place the modal footer content in.
 * @fires close - Dispatched whenever the modal is closed.
 * @fires cancel - Dispatched whenever the modal is canceled. Cancelling the event prevents the modal from closing.
 */
let Modal = class Modal extends LitElement {
  constructor() {
    super(...arguments);
    /** Whether the modal is opened. */
    this.open = false;
    /** The inline size of the modal. */
    this.inlineSize = "m";
    /** The block size of the modal. */
    this.blockSize = "fit-content";
    /** The padding of the content. */
    this.contentPadding = "m";
    /** Hides the close button in the header. */
    this.hideClose = false;
    this.hasFooterContent = false;
  }
  updated(changedProperties) {
    if (changedProperties.has("open")) {
      this.open ? this.dialog.showModal() : this.dialog.close();
    }
  }
  render() {
    const closeButtonHTML = this.hideClose ? nothing : html`<bi-button
          class="bi-modal-header-close"
          variant="plain"
          square
          size="s"
          @click=${this.close}
          ><svg
            class="bi-modal-header-close-icon"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path
              d="m496.8 87.4c20-20 20-52.5 0-72.4-20-20-52.5-20-72.4 0l-168.4 168.6-168.6-168.4c-20-20-52.5-20-72.4 0s-20 52.5 0 72.4l168.6 168.4-168.4 168.6c-20 20-20 52.5 0 72.4 20 20 52.5 20 72.4 0l168.4-168.6 168.6 168.4c20 20 52.5 20 72.4 0 20-20 20-52.5 0-72.4l-168.6-168.4z"
            />
          </svg>
        </bi-button>`;
    return html`
      <dialog
        class="bi-modal"
        @close=${this.handleDialogClosed}
        @cancel=${this.handleDialogCancel}
      >
        <div class="bi-modal-inner">
          <div class="bi-modal-header">
            <div class="bi-modal-header-content">
              <slot name="header-title"></slot>
            </div>
            ${closeButtonHTML}
          </div>
          <div class="bi-modal-content">
            <slot></slot>
          </div>
          <div
            class=${classMap({
      "bi-modal-footer": true,
      "bi-modal-footer--hasContent": this.hasFooterContent
    })}
          >
            <slot
              name="footer"
              @slotchange=${this.handleFooterSlotChange}
            ></slot>
          </div>
        </div>
      </dialog>
    `;
  }
  /** Opens the modal programmatically. */
  showModal() {
    this.open = true;
  }
  /** Closes the modal programmatically. */
  close() {
    this.open = false;
  }
  handleDialogCancel(event) {
    if (!this.dispatchEvent(new Event("cancel", {
      bubbles: false,
      cancelable: true
    }))) {
      event.preventDefault();
    } else {
      this.close();
    }
  }
  handleDialogClosed() {
    this.open = false;
    this.dispatchEvent(new Event("close", {
      bubbles: false
    }));
  }
  handleFooterSlotChange() {
    var _a;
    this.hasFooterContent = ((_a = this.querySelectorAll("[slot='footer']")) === null || _a === void 0 ? void 0 : _a.length) > 0;
  }
};
Modal.styles = modalStyles;
__decorate([query("dialog")], Modal.prototype, "dialog", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
})], Modal.prototype, "open", void 0);
__decorate([property({
  attribute: "inline-size"
})], Modal.prototype, "inlineSize", void 0);
__decorate([property({
  attribute: "block-size"
})], Modal.prototype, "blockSize", void 0);
__decorate([property({
  attribute: "content-padding"
})], Modal.prototype, "contentPadding", void 0);
__decorate([property({
  type: Boolean,
  attribute: "hide-close"
})], Modal.prototype, "hideClose", void 0);
__decorate([state()], Modal.prototype, "hasFooterContent", void 0);
Modal = __decorate([customElement("bi-modal")], Modal);
export { Modal };
