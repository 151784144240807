var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from "lit";
import { customElement, property, query, queryAssignedElements, state } from "lit/decorators.js";
import "element-internals-polyfill";
import { classMap } from "lit/directives/class-map.js";
/**
 * `FormControl` is the base class from which all Bingli components that are meant to be used in a form extend from. It doesn't expose its own component.
 * @cssprop [--bi-form-control-label-size = var(--bi-size-font-m)] - The size of the label.
 * @fires {Event} input - Fired when the value has been changed.
 * @fires {Event} change - Fired when an alteration to the value is committed by the user.
 */
let FormControl = class FormControl extends LitElement {
  constructor() {
    super();
    /** The value of the control. */
    this.value = "";
    /** Makes the control required. */
    this.required = false;
    /** Makes the control disabled. */
    this.disabled = false;
    this.hasSlottedSublabel = false;
    /** Observer for the disabled attribute on the control. */
    this.observerDisabled = undefined;
    /** A random id to link the label and input together. */
    this.randomId = Math.random().toString(36).substring(2);
    /** Internal type of the control to appropriately emit the correct events. */
    this.formControlType = "input";
    // Attach the internals
    this.internals = this.attachInternals();
  }
  firstUpdated() {
    this.setupDisabledObserver();
    this.handleSublabelSlotChange();
  }
  updated(changedProperties) {
    if (changedProperties.has("value")) {
      this.setFormValue();
    }
  }
  disconnectedCallback() {
    var _a;
    super.disconnectedCallback();
    (_a = this.observerDisabled) === null || _a === void 0 ? void 0 : _a.disconnect();
  }
  /** Returns the label HTML. */
  getLabelHTML(includeChevron = false) {
    return this.label ? html`<label for=${this.randomId} class="bi-form-control-label"
          >${this.label}${this.required ? "*" : ""}
          <slot name="label-end"></slot>
          ${includeChevron ? this.getLabelChevronHTML() : nothing}
        </label>` : nothing;
  }
  getLabelChevronHTML() {
    return html`<span class="bi-form-control-label-chevron">
      <svg
        viewBox="0 0 320 512"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path
          d="m310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l169.4-169.4-169.3-169.4c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
        />
      </svg>
    </span>`;
  }
  /** Returns the sublabel HTML. */
  getSublabelHTML() {
    const classes = classMap({
      "bi-form-control-sublabel": true,
      "bi-form-control-sublabel--empty": !this.hasSlottedSublabel && !this.sublabel
    });
    return html`<div class=${classes}>
      <slot name="sublabel" @slotchange=${this.handleSublabelSlotChange}>
        ${this.sublabel}
      </slot>
    </div>`;
  }
  handleSublabelSlotChange() {
    this.hasSlottedSublabel = this.slottedSublabel.length > 0;
  }
  /** Returns the error HTML. */
  getErrorHTML() {
    return this.error ? html`<div class="bi-form-control-error" role="alert">
          ${this.error}
        </div>` : nothing;
  }
  /** Handles an input event on the control. */
  handleInput(event) {
    event.stopPropagation();
    const target = event.target;
    this.setValue(target.value);
  }
  /** Handles a change event on the control. */
  handleChange(event) {
    event.stopPropagation();
    if (!["radio", "checkbox", "select"].includes(this.formControlType)) {
      this.dispatchEvent(new Event("change", {
        bubbles: true,
        composed: true
      }));
    }
  }
  /** Sets the value and dispatches an input event */
  setValue(value) {
    this.value = value;
    this.dispatchEvent(new Event("input", {
      bubbles: true,
      composed: true
    }));
    if (["radio", "checkbox", "select"].includes(this.formControlType)) {
      this.dispatchEvent(new Event("change", {
        bubbles: true,
        composed: true
      }));
    }
  }
  /** Set the value in the associated form */
  setFormValue() {
    this.internals.setFormValue(this.value || "");
  }
  /** Syncs the disabled attribute of the host with the control. */
  syncDisabled() {
    if (this.control.disabled) {
      this.setAttribute("disabled", "");
    } else {
      this.removeAttribute("disabled");
    }
  }
  /** Sets up an observer for the disabled attribute of the control element, which in turn updates the disabled attribute on the host accordingly. */
  setupDisabledObserver() {
    this.observerDisabled = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === "disabled") {
          this.syncDisabled();
        }
      });
    });
    if (this.control) {
      this.observerDisabled.observe(this.control, {
        attributes: true
      });
      this.syncDisabled();
    }
  }
};
/** Associates the control with a form. */
FormControl.formAssociated = true;
__decorate([query("input, textarea, select")], FormControl.prototype, "control", void 0);
__decorate([queryAssignedElements({
  slot: "sublabel"
})], FormControl.prototype, "slottedSublabel", void 0);
__decorate([property({
  attribute: "name"
})], FormControl.prototype, "controlName", void 0);
__decorate([property()], FormControl.prototype, "value", void 0);
__decorate([property()], FormControl.prototype, "label", void 0);
__decorate([property()], FormControl.prototype, "sublabel", void 0);
__decorate([property({
  type: Boolean
})], FormControl.prototype, "required", void 0);
__decorate([property({
  type: Boolean
})], FormControl.prototype, "disabled", void 0);
__decorate([property()], FormControl.prototype, "error", void 0);
__decorate([state()], FormControl.prototype, "hasSlottedSublabel", void 0);
FormControl = __decorate([customElement("bi-form-control")], FormControl);
export { FormControl };
