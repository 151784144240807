import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { GraphQLModule } from './graphql/graphql.module';
import { HttpConfigInterceptor } from './interceptor/http-config-interceptor.interceptor';
import { HttpLoaderFactory } from './shared/intl/translation.loader';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { provideBingliAuthConfig } from '@mybingli/security-service';
import { NavigationComponent } from './shared/components/navigation/navigation.component';

@NgModule({
  declarations: [AppComponent, AuthCallbackComponent],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    GraphQLModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    NavigationComponent,
  ],
  providers: [
    [
      provideBingliAuthConfig({
        useLocalStorage: true,
        environment: {
          bossUrl: environment.bossApi,
          authUrl: environment.authUrl,
        },
      }),
    ],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
