import { BingliAuthService } from '@mybingli/security-service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: [],
})
export class AuthCallbackComponent {
  constructor(
    private bingliAuthService: BingliAuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    try {
      this.bingliAuthService.completeAuthentication();
      this.router.navigate(['']);
    } catch (error) {
      console.error(error);
    }
  }
}
