var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import tabStyles from "./tab.styles";
import { LightLinkController } from "../shared/controllers/light-link.controller";
import { ifDefined } from "lit/directives/if-defined.js";
/**
 * A single tab in a tab group.
 * @slot - The slot to place the tab content in.
 * @slot link - Use this slot as an alternative to the href property to render a button as a link. Useful for links that are dynamically generated. For example Angular which uses routerLink to generate the href if it is applied to an anchor tag.
 * @cssprop [--bi-tab-block-size = var(--bi-size-control-block-m)] - The block size of the tab.
 * @fires {Event} selected - Fired when the tab is selected.
 */
let Tab = class Tab extends LitElement {
  constructor() {
    super(...arguments);
    /** Whether the tab is selected. */
    this.selected = false;
    /** When provided together with a href property, determines where to open the linked URL. */
    this.target = "_self";
    this.text = this.textContent || "";
    this.observerText = undefined;
    this.lightLink = new LightLinkController(this, () => {
      var _a;
      return (_a = this.slottedLinks) === null || _a === void 0 ? void 0 : _a[0];
    });
  }
  firstUpdated() {
    this.observeText();
  }
  updated(changedProperties) {
    if (changedProperties.has("selected")) {
      this.handleSelectedChanged();
    }
    if (this.lightLink.text) {
      this.setAttribute("light-link", "");
    } else {
      this.removeAttribute("light-link");
    }
  }
  /** Schedules the update to occur after the next frame paints */
  async scheduleUpdate() {
    await new Promise(resolve => setTimeout(resolve));
    super.scheduleUpdate();
  }
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "tab");
  }
  disconnectedCallback() {
    var _a;
    super.disconnectedCallback();
    (_a = this.observerText) === null || _a === void 0 ? void 0 : _a.disconnect();
  }
  render() {
    const contentHTML = html`<slot></slot> ${this.lightLink.text || ""}`;
    const tabHTML = this.href ? html`
          <a
            class="bi-tab"
            data-text="${this.text || ""}"
            href=${this.href}
            target=${ifDefined(this.target)}
          >
            ${contentHTML}
          </a>
        ` : html`
          <div
            class="bi-tab"
            data-text="${this.lightLink.text || this.text || ""}"
          >
            ${contentHTML}
          </div>
        `;
    return html`${tabHTML} <slot name="link"></slot>`;
  }
  observeText() {
    this.observerText = new MutationObserver(() => this.text = this.textContent || "");
    this.observerText.observe(this, {
      characterData: true,
      childList: true
    });
  }
  handleSelectedChanged() {
    this.setAttribute("aria-selected", `${this.selected}`);
    this.setAttribute("tabindex", this.selected ? "0" : "-1");
  }
};
Tab.styles = tabStyles;
__decorate([queryAssignedElements({
  slot: "link",
  selector: "a"
})], Tab.prototype, "slottedLinks", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
})], Tab.prototype, "selected", void 0);
__decorate([property()], Tab.prototype, "href", void 0);
__decorate([property()], Tab.prototype, "target", void 0);
__decorate([state()], Tab.prototype, "text", void 0);
Tab = __decorate([customElement("bi-tab")], Tab);
export { Tab };
