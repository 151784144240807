import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '@mybingli/security-service/lib/models/boss-models';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private httpClient = inject(HttpClient);
  private cache: {
    supportedLanguages?: Observable<ApiResponse<string[]>>;
  } = {};

  getSupportedLanguages() {
    this.cache.supportedLanguages ??= this.httpClient
      .get<ApiResponse<string[]>>(
        `${environment.translationApiUrl}/tenant-api/supported-languages`
      )
      .pipe(shareReplay(1));
    return this.cache.supportedLanguages;
  }
}
