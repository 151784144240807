import { BingliAuthService } from '@mybingli/security-service';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PreviewService } from '@shared/services/preview.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private bingliAuthService = inject(BingliAuthService);
  private previewService = inject(PreviewService);
  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean | void {
    const { token, refreshToken } = activatedRouteSnapshot.queryParams;
    if (token) {
      this.previewService.enablePreviewModeWithToken(token);
      this.previewService.saveRefreshTokenToLocalStorage(refreshToken);
    }
    if (
      this.bingliAuthService.isAuthenticated() ||
      this.previewService.isPreviewMode
    ) {
      const roles = activatedRouteSnapshot?.data?.['roles'] || [];
      return roles.length
        ? this.bingliAuthService.isAuthorizedFor(roles)
        : true;
    }
    this.bingliAuthService.login();
  }
}
