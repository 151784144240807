import { AboutDialogComponent } from './about-dialog.component';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AboutDialogComponent],
  imports: [
    CommonModule,
    BaseDialogModule,
    TranslateModule,
    NgOptimizedImage,
    AsyncPipe,
  ],
  exports: [AboutDialogComponent],
})
export class AboutDialogModule {}
