export class ResizeController {
  constructor(host, target) {
    this.target = target;
    this.hadFirstUpdate = false;
    host.addController(this);
    this.observer = new ResizeObserver(([entry]) => {
      const [borderBoxSize] = entry.borderBoxSize;
      this.borderBoxSize = borderBoxSize;
      host.requestUpdate();
    });
  }
  hostUpdated() {
    if (!this.hadFirstUpdate) {
      this.observer.observe(this.target());
      this.hadFirstUpdate = true;
    }
  }
  hostConnected() {
    // in case the host has rendered, disconnected, and is now reconnected
    if (this.hadFirstUpdate) {
      this.observer.observe(this.target());
    }
  }
  hostDisconnected() {
    this.observer.disconnect();
  }
}
