import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
import formControlStyles from "../shared/styles/form-control.styles";
export default [baseStyles, formControlStyles, css`
    ::slotted(option) {
      display: none;
    }

    :host {
      --_bi-select-max-inline-size: max-content;
      --_bi-dropdown-content-padding: 0;
      --_bi-dropdown-item-max-inline-size: none;
      --bi-popover-max-inline-size: var(
        --bi-select-dropdown-max-inline-size,
        var(--bi-size-layout-xxs)
      );
      --bi-dropdown-max-block-size: 304px;

      -webkit-user-select: none;
      user-select: none;
      inline-size: 100%;
      max-inline-size: var(--_bi-select-max-inline-size);
      display: flex;
      flex-direction: column;
      align-items: var(--_bi-select-align-items, flex-start);
      gap: var(--bi-size-space-xs);
    }

    :host([expand]) {
      --_bi-select-max-inline-size: none;
      --_bi-select-align-items: stretch;
    }

    :host([searchable]) {
      --bi-dropdown-max-block-size: 360px;
    }

    .bi-select {
      position: relative;
      inline-size: 100%;
    }

    .bi-select-control {
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      inline-size: 100%;
      block-size: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
      display: none;
    }
    .bi-select-control:not(:disabled):active {
      opacity: var(--bi-opacity-active);
    }

    .bi-select-dropdown-item--hovered {
      --_bi-dropdown-item-background-color: var(--bi-color-primary-default);
      --_bi-dropdown-item-color: var(--bi-color-text-on-dark);
      --_bi-dropdown-item-slot-color: var(--bi-color-text-on-dark);
    }

    .bi-select-dropdown-item-icon-inner {
      inline-size: var(--bi-size-font-m);
      fill: currentColor;
    }

    .bi-select-dropdown-content {
      display: flex;
      flex-direction: column;
      padding: var(--bi-size-space-xs);
      gap: var(--bi-size-space-xs);
    }

    .bi-select-search {
      position: sticky;
      inset-block-start: 0;
      background-color: var(--bi-color-background);
      padding-block-start: var(--bi-size-space-xs);
      margin-block-start: calc(var(--bi-size-space-xs) * -1);
    }

    @media (hover: none) {
      .bi-select-control {
        display: block;
      }
      .bi-select-dropdown {
        pointer-events: none;
      }
    }
  `];
